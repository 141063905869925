import { render, staticRenderFns } from "./index.vue?vue&type=template&id=571d96aa"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"
import style0 from "./index.vue?vue&type=style&index=0&id=571d96aa&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../../../../../../node_modules/.pnpm/vue-loader@15.11.1_babel-core@6.26.3_css-loader@4.3.0_lodash@4.17.21_prettier@1.19.1_vue-temp_lls4wxv5ipnxzldxaoogv2ybrq/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports