var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "todays-top-deals" } },
    [
      _c("content-card", [
        _c(
          "div",
          {
            staticClass:
              "col-lg-5 d-flex flex-column justify-content-center headline"
          },
          [
            _c("h1", { staticClass: "mb-0" }, [
              _vm._v("\n                Today's Top Deals\n            ")
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "location" }, [
              _vm.location
                ? _c(
                    "span",
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.$t("component.todaysTopDeals.for")) +
                          "\n                    " +
                          _vm._s(_vm.cityState) +
                          "\n                    "
                      ),
                      _c(
                        "b-link",
                        {
                          staticClass: "font-italic",
                          on: {
                            click: function($event) {
                              return _vm.editLocation()
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.$t("component.todaysTopDeals.edit")) +
                              "\n                    "
                          )
                        ]
                      )
                    ],
                    1
                  )
                : _c("span", [
                    _vm._v("\n                     \n                ")
                  ])
            ])
          ]
        ),
        _vm._v(" "),
        _vm.showSavings
          ? _c("div", { staticClass: "col-lg-7 actions" }, [
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "col-sm-4 d-flex flex-column justify-content-center align-items-center action",
                    class: { "px-0": _vm.isSpanish }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "label mb-1 mb-sm-0" },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(
                              _vm.$t("component.todaysTopDeals.biggestSavings")
                            ) +
                            "\n                        "
                        ),
                        _vm.biggestSavings
                          ? _c("tooltip-disclaimer", {
                              attrs: { title: _vm.biggestSavings.disclaimer }
                            })
                          : _vm._e()
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "loading-btn",
                      {
                        class: { "p-0": _vm.isSpanish },
                        style: [_vm.isSpanish ? { fontSize: "15px" } : null],
                        attrs: {
                          loading: _vm.loadingAll,
                          variant: "outline-primary",
                          size: "lg"
                        },
                        on: { click: () => _vm.gotoNewSortedBy("savings,desc") }
                      },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(
                              _vm._f("numeral")(
                                _vm._f("emptyDash")(
                                  _vm.biggestSavings,
                                  "value"
                                ),
                                "$0,0"
                              )
                            ) +
                            "\n                        " +
                            _vm._s(_vm.$t("component.todaysTopDeals.offMsrp")) +
                            "\n                    "
                        )
                      ]
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "col-sm-4 d-flex flex-column justify-content-center align-items-center action",
                    class: { "px-0": _vm.isSpanish }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "label mb-1 mb-sm-0" },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(
                              _vm.$t("component.todaysTopDeals.lowestPrice")
                            ) +
                            "\n                        "
                        ),
                        _vm.lowestPrice
                          ? _c("tooltip-disclaimer", {
                              attrs: { title: _vm.lowestPrice.disclaimer }
                            })
                          : _vm._e()
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "loading-btn",
                      {
                        attrs: {
                          loading: _vm.loadingAll,
                          variant: "outline-primary",
                          size: "lg"
                        },
                        on: { click: () => _vm.gotoNewSortedBy("price,asc") }
                      },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(
                              _vm._f("numeral")(
                                _vm._f("emptyDash")(_vm.lowestPrice, "value"),
                                "$0,0"
                              )
                            ) +
                            "\n                        " +
                            _vm._s(_vm.newOrUsed) +
                            "\n                    "
                        )
                      ]
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "col-sm-4 d-flex flex-column justify-content-center align-items-center action",
                    class: { "px-0": _vm.isSpanish }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "label mb-1 mb-sm-0" },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(
                              _vm.$t("component.todaysTopDeals.lowestPayment")
                            ) +
                            "\n                        "
                        ),
                        _vm.lowestPayment
                          ? _c("tooltip-disclaimer", {
                              attrs: { title: _vm.lowestPayment.disclaimer }
                            })
                          : _vm._e()
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "loading-btn",
                      {
                        attrs: {
                          loading: _vm.loadingAll,
                          variant: "outline-primary",
                          size: "lg"
                        },
                        on: {
                          click: () =>
                            _vm.gotoNewSortedBy("quote.monthlyPayment,asc")
                        }
                      },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(
                              _vm._f("numeral")(
                                _vm._f("emptyDash")(_vm.lowestPayment, "value"),
                                "$0"
                              )
                            ) +
                            _vm._s(_vm.$t("component.todaysTopDeals.mo")) +
                            "\n                        " +
                            _vm._s(_vm.financeOrLease) +
                            "\n                    "
                        )
                      ]
                    )
                  ],
                  1
                )
              ])
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.showComingSoon
          ? _c(
              "div",
              {
                staticClass:
                  "col-lg-7 coming-soon d-flex flex-column justify-content-center text-lg-left text-center pt-lg-0 pt-3"
              },
              [
                _c(
                  "div",
                  { staticClass: "d-flex justify-content-center" },
                  [
                    _c(
                      "b-button",
                      {
                        staticClass: "mx-3",
                        attrs: {
                          variant: "outline-primary",
                          size: "xlg",
                          href: "/pick-your-flow"
                        }
                      },
                      [
                        _vm._v(
                          "\n                    Search Local Listings\n                "
                        )
                      ]
                    )
                  ],
                  1
                )
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.showLocationEditor
          ? _c(
              "div",
              { staticClass: "col-lg-7 unknown-location" },
              [
                _c("p", [
                  _vm._v(_vm._s(_vm.$t("component.todaysTopDeals.enterZip")))
                ]),
                _vm._v(" "),
                _c(
                  "b-form",
                  {
                    on: {
                      submit: function($event) {
                        $event.preventDefault()
                        return _vm.saveZipCode.apply(null, arguments)
                      }
                    }
                  },
                  [
                    _c("b-container", [
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          { staticClass: "col-sm-8 mb-sm-0 mb-3 mr-1" },
                          [
                            _c("b-form-input", {
                              attrs: {
                                type: "text",
                                size: "lg",
                                placeholder: _vm.zipCodePlaceholder,
                                state: _vm.isValidZipCode,
                                maxlength: "5"
                              },
                              model: {
                                value: _vm.zipCode,
                                callback: function($$v) {
                                  _vm.zipCode = $$v
                                },
                                expression: "zipCode"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-sm-3 ml-1" },
                          [
                            _c(
                              "b-button",
                              {
                                staticClass: "d-block",
                                attrs: {
                                  type: "submit",
                                  variant: "primary",
                                  block: "",
                                  size: "lg"
                                }
                              },
                              [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(
                                      _vm.$t("component.todaysTopDeals.save")
                                    ) +
                                    "\n                            "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _vm.showError
                        ? _c("p", { staticClass: "text-danger" }, [
                            _vm._v(
                              "\n                        " +
                                _vm._s(
                                  _vm.$t(
                                    "component.todaysTopDeals.zipCodeNotFound"
                                  )
                                ) +
                                "\n                    "
                            )
                          ])
                        : _vm._e()
                    ])
                  ],
                  1
                )
              ],
              1
            )
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }