var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "video-button" }, [
      _c(
        "span",
        {
          staticClass:
            "wistia_embed wistia_async_ylo4zgokrw popover=true popoverContent=link",
          staticStyle: { display: "inline", position: "relative" }
        },
        [
          _c("i", {
            staticClass: "ion-ios-play",
            attrs: { "aria-hidden": "true" }
          })
        ]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }