<template>
    <b-row>
        <b-col v-if="!hidePaymentType" cols="12" sm="" class="mb-2 mb-sm-0">
            <b-form-select
                v-model="selectedPaymentType"
                :options="paymentTypeOptions"
                :class="{ selected: selectedPaymentType }"
                @change="paymentTypeChanged"
            />
        </b-col>
        <b-col class="mb-2 mb-sm-0">
            <b-form-select
                v-model="selectedMinPayment"
                :options="minPaymentOptions"
                :class="{ selected: selectedMinPayment }"
                @change="minPaymentChanged"
            />
        </b-col>
        <div class="to d-flex align-items-center mb-2 mb-sm-0">
            to
        </div>
        <b-col class="mb-2 mb-sm-0">
            <b-form-select
                v-model="selectedMaxPayment"
                :options="maxPaymentOptions"
                :class="{ selected: selectedMaxPayment }"
                @change="maxPaymentChanged"
            />
        </b-col>
        <b-col cols="12" sm="3">
            <search-button
                :stock-type="stockType"
                :active="$options.name"
            />
        </b-col>
    </b-row>
</template>

<style lang="scss" scoped>
.to {
    color: #717171;
    font-family: $helvetica-56-italic;
    font-style: italic;
    font-size: px2rem(14);
}
</style>

<script>
import _ from "lodash";
import numeral from "numeral";
import { sync } from "vuex-pathify";
import SearchButton from "./SearchButton";
import {trackGAEvent} from "@/lib/GoogleTagManager";

export default {
    name: "Payment",
    components: {SearchButton},
    props: {
        hidePaymentType: {
            type: Boolean,
            required: false,
            default: false
        },
        stockType: {
            type: String,
            required: true
        }
    },

    data() {
        return {
            selectedPaymentType: null,
            selectedMinPayment: null,
            selectedMaxPayment: null
        };
    },

    mounted() {
        if(this.isPaymentTypeSelected) {
            this.selectedPaymentType = this.updatedPaymentType;
        }
        if(this.isMinPaymentSelected) {
            this.selectedMinPayment = this.updatedMinPayment;
        }
        if(this.isMaxPaymentSelected) {
            this.selectedMaxPayment = this.updatedMaxPayment;
        }
    },

    computed: {
        updatedMinPayment: sync('homeSearch/minPayment'),
        updatedMaxPayment: sync('homeSearch/maxPayment'),
        updatedPaymentType: sync('homeSearch/paymentType'),

        isMinPaymentSelected() { return !_.isNil(this.updatedMinPayment); },
        isMaxPaymentSelected() { return !_.isNil(this.updatedMaxPayment); },
        isPaymentTypeSelected() { return !_.isNil(this.updatedPaymentType); },

        paymentTypeOptions() {
            return [
                { value: null, text: "Payment type: All" },
                { value: "FINANCE", text: "Finance" },
                { value: "LEASE", text: "Lease" }
            ];
        },
        minPaymentOptions() {
            const options = [];

            options.push({ value: null, text: "Min: Any" });

            _.range(100, 1001, 100).forEach(payment => {
                options.push({
                    value: payment,
                    text: numeral(payment).format("$0,0") + "/mo"
                });
            });

            return options;
        },
        maxPaymentOptions() {
            const options = [];

            _.range(200, 1001, 100).forEach(payment => {
                options.push({
                    value: payment,
                    text: numeral(payment).format("$0,0") + "/mo"
                });
            });

            options.push({ value: null, text: "Max: Any" });

            return options;
        }
    },

    methods: {
        minPaymentChanged(minPayment) {
            if (minPayment >= this.selectedMaxPayment) {
                this.selectedMaxPayment = null;
                this.updatedMaxPayment = null;
            }
            this.updatedMinPayment = minPayment;
        },
        maxPaymentChanged(maxPayment) {
            if (maxPayment <= this.selectedMinPayment) {
                this.selectedMinPayment = null;
                this.updatedMinPayment = null;
            }
            this.updatedMaxPayment = maxPayment;
        },
        paymentTypeChanged() {
            this.updatedPaymentType = this.selectedPaymentType;
        },
        trackGAEventPayment() {
            trackGAEvent(this, {
                category: "home-search",
                action: `search-${this.stockType}-payments`,
                label: `${this.selectedMinPayment || "ANY"} - ${this
                    .selectedMaxPayment || "ANY"} - ${this
                    .selectedPaymentType || "ALL"}`
            });
        }
    }
};
</script>
