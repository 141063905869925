import { make } from "vuex-pathify";

const initialState = {
    selectedMake: null,
    selectedModel: null,
    selectedBodyStyle: null,
    selectedBodyStyles: [],
    minPrice: null,
    maxPrice: null,
    paymentType: null,
    minPayment: null,
    maxPayment: null
};

const mutations = {
    ...make.mutations(initialState),
    UPDATE_SELECTED_MAKE(state, updatedMake) {
        state.selectedMake = updatedMake;
    },
    UPDATE_SELECTED_MODEL(state, updatedModel) {
        state.selectedModel = updatedModel;
    },
    UPDATE_SELECTED_BODY_STYLE(state, updatedBodyStyle) {
        state.selectedBodyStyle = updatedBodyStyle;
    },
    UPDATE_SELECTED_BODY_STYLES(state, updatedBodyStyles) {
        state.selectedBodyStyles = updatedBodyStyles;
    },
    UPDATE_MIN_PRICE(state, minPrice) {
        state.minPrice = minPrice;
    },
    UPDATE_MAX_PRICE(state, maxPrice) {
        state.maxPrice = maxPrice;
    },
    UPDATE_PAYMENT_TYPE(state, paymentType) {
        state.paymentType = paymentType;
    },
    UPDATE_MIN_PAYMENT(state, minPayment) {
        state.minPayment = minPayment;
    },
    UPDATE_MAX_PAYMENT(state, maxPayment) {
        state.maxPayment = maxPayment;
    }
};

const actions = {
    ...make.actions(initialState),
    updateSelectedMake({ commit }, make) {
        commit("UPDATE_SELECTED_MAKE", make);
    },
    updateSelectedModel({ commit }, model) {
        commit("UPDATE_SELECTED_MODEL", model);
    },
    updateSelectedBodyStyle({ commit }, bodyStyle) {
        commit("UPDATE_SELECTED_BODY_STYLE", bodyStyle);
    },
    updateSelectedBodyStyles({ commit }, bodyStyles) {
        commit("UPDATE_SELECTED_BODY_STYLE", bodyStyles);
    },
    updateMinPrice({ commit }, minPrice) {
        commit("UPDATE_PRICE", minPrice);
    },
    updateMaxPrice({ commit }, maxPrice) {
        commit("UPDATE_PRICE", maxPrice);
    },
    updatePaymentType({ commit }, paymentType) {
        commit("UPDATE_PAYMENT_TYPE", paymentType);
    },
    updateMinPayment({ commit }, minPayment) {
        commit("UPDATE_MIN_PAYMENT", minPayment);
    },
    updateMaxPayment({ commit }, maxPayment) {
        commit("UPDATE_MAX_PAYMENT", maxPayment);
    }
};

const getters = {
    ...make.getters(initialState)
};

export default {
    namespaced: true,
    state: initialState,
    mutations,
    actions,
    getters
};
