<template>
    <div class="d-flex justify-content-center">
        <div
            class="walmart-cta-block w-100 text-center d-flex flex-column align-items-center justify-content-center p-3"
        >
            <h1 class="title mx-5">
                Everyday Low Prices on New & Used Cars
            </h1>

            <h2>Lifetime Warranty Standard*</h2>

            <b-tabs class="search-wrapper w-100" no-fade no-nav-style fill>
                <b-tab title="New">
                    <new-search />
                </b-tab>
                <b-tab title="Used">
                    <used-search />
                </b-tab>
            </b-tabs>
        </div>
    </div>
</template>

<script>
import NewSearch from "./NewSearch";
import UsedSearch from "./UsedSearch";

export default {
    name: "HomeSearch",
    components: { NewSearch, UsedSearch }
};
</script>

<style lang="scss">
.walmart-cta-block {
    border-radius: 4px;
    background-color: rgba($primary-blue, 0.9);
    margin: -40px 15px 0;
    color: $very-light-gray;
    max-width: 1140px;
    z-index: 1;

    @include media-breakpoint-up(sm) {
        margin-top: -100px;
    }

    .title {
        font-size: px2rem(26);
        font-family: $helvetica-55-Roman;
    }

    h2 {
        font-size: px2rem(14);
        font-family: $helvetica-56-italic;
        font-style: italic;
    }

    .search-wrapper {
        .nav-item {
            font-size: px2rem(16);

            @include media-breakpoint-up(sm) {
                max-width: 160px;
            }

            &:first-child {
                .nav-link.active {
                    box-shadow: 3px 0 10px 0 rgba(0, 0, 0, 0.2);

                    & {
                        position: relative;
                    }
                }
            }

            &:last-child {
                .nav-link.active {
                    box-shadow: -3px 0 10px 0 rgba(0, 0, 0, 0.2);

                    & {
                        position: relative;
                    }
                }
            }

            .nav-link {
                background-color: $light-gray;
                color: #717171;
                font-family: $helvetica-56-italic;
                font-style: italic;
                border-top-right-radius: 4px;
                border-top-left-radius: 4px;

                &.active {
                    background-color: #fff;
                    color: $primary-blue;
                    font-family: $helvetica-75-bold;
                    font-weight: bold;
                    font-style: normal;
                }
            }
        }

        .tab-content {
            background-color: #fff;
            position: relative;

            button.btn-orange {
                font-size: px2rem(14);
                font-family: $helvetica-75-bold;
                font-weight: bold;
            }

            select.custom-select {
                border: solid 1px #eaeaea;
                font-size: px2rem(14);
                color: #717171;
                font-family: $helvetica-56-italic;
                background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' id='caret-down-outline' class='ionicon' fill='%23007DC6' viewBox='0 0 512 512'%3e%3cpath d='M98 190.06l139.78 163.12a24 24 0 0036.44 0L414 190.06c13.34-15.57 2.28-39.62-18.22-39.62h-279.6c-20.5 0-31.56 24.05-18.18 39.62z'%3e%3c/path%3e%3c/svg%3e")
                    right 0.75rem center/8px 10px no-repeat #f9f9f9;
                border-radius: 0;

                &.selected {
                    font-family: $helvetica-55-Roman;
                    font-style: normal;
                }
            }
        }
    }
}
</style>
