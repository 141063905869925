var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "learn-more-button",
    {
      attrs: { disabled: _vm.isDisabled, variant: _vm.variant, size: _vm.size },
      on: { click: _vm.handleClick }
    },
    [
      !_vm.loading ? _c("span", [_vm._t("default")], 2) : _vm._e(),
      _vm._v(" "),
      _vm.loading
        ? _c(
            "div",
            { staticClass: "d-flex justify-content-center" },
            [_c("loading-spinner", { attrs: { size: 30 } })],
            1
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }