<template>
    <div class="carsaver-at-walmart-hero">
        <div
            class="walmart-hero hero-full-height bg-img d-flex align-items-end justify-content-between position-relative"
        >
            <div class="play-button-wrapper">
                <video-button />
            </div>
        </div>
        <home-search />
    </div>
</template>

<script>
import HomeSearch from "./HomeSearch";
import VideoButton from "Modules/Home/components/Hero/components/VideoButton";
export default {
    name: "CarsaverAtWalmartHero",
    components: { VideoButton, HomeSearch }
};
</script>

<style lang="scss">
.webp {
    .carsaver-at-walmart-hero {
        .walmart-hero.bg-img {
            background: url("./images/walmart-hero-xl.webp") no-repeat 0 30%;

            @include media-breakpoint-down(md) {
                background: url("./images/walmart-hero-md.webp") no-repeat
                    center 25%;
            }

            @include media-breakpoint-down(xs) {
                background: url("./images/walmart-hero-sm.webp") no-repeat
                    center center;
            }
        }
    }
}

.no-webp {
    .carsaver-at-walmart-hero {
        .walmart-hero.bg-img {
            background: url("./images/walmart-hero-xl.jpg") no-repeat 0 30%;

            @include media-breakpoint-down(md) {
                background: url("./images/walmart-hero-md.jpg") no-repeat center
                    25%;
            }

            @include media-breakpoint-down(xs) {
                background: url("./images/walmart-hero-sm.jpg") no-repeat center
                    center;
            }
        }
    }
}
</style>

<style lang="scss">
.carsaver-at-walmart-hero {
    position: relative;

    .walmart-hero {
        height: 440px;
        overflow: hidden;
        background-size: cover !important;
        padding-bottom: 50px;

        @include media-breakpoint-down(sm) {
            height: 210px !important;
        }

        @include media-breakpoint-only(md) {
            min-height: calc(100vw / 2.6) !important;
        }

        .play-button-wrapper {
            position: absolute;
            top: calc(50% - 60px);
            left: calc(50% - 10px);

            @include media-breakpoint-up(sm) {
                top: calc(50% - 75px);
            }
        }

        .text-wrapper {
            height: 240px;
            width: 450px;
            background-color: rgba(0, 76, 145, 0.78);
            color: $white;
            font-size: px2em(40);
            letter-spacing: 0.05px;
            line-height: 50px;
            padding: 35px 35px 35px 60px;

            .title {
                font-family: $helvetica-55-Roman;
            }

            hr {
                color: $white;
                border-color: $white;
                border-style: solid;
            }

            h2 {
                font-family: $helvetica-46-light-italic;
                padding-top: 5px;
            }
        }

        .logo-wrapper {
            height: 80px;
            width: 232px;
            margin-right: 22px;

            img {
                height: 100%;
                width: auto;
            }
        }
    }

    @include media-breakpoint-up(xl) {
        .walmart-hero {
            background-position-y: -220px;
        }
    }

    @include media-breakpoint-down(md) {
        .walmart-hero.hero-full-height {
        }
    }

    @include media-breakpoint-down(sm) {
        .cs-buttons {
            bottom: 160px !important;
        }

        .cs-anchor {
            h2 {
                font-size: px2em(16) !important;
            }
        }
    }
}
</style>
