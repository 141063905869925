var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { ref: "video", staticClass: "video-background" }, [
    _c(
      "div",
      {
        class: "wistia_embed wistia_async_" + _vm.videoId + " wmode=transparent"
      },
      [_vm._v("\n         \n    ")]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }