var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.showSell
    ? _c(
        "div",
        { staticClass: "d-flex p-sm-0" },
        [
          _c(
            "b-container",
            { staticClass: "mt-3", attrs: { fluid: "" } },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    {
                      staticClass: "px-lg-0 pr-md-0 d-none d-md-block",
                      attrs: { cols: "12", md: "7" }
                    },
                    [
                      _c("div", { staticClass: "w-100 h-100 syc-banner" }, [
                        _c(
                          "div",
                          { staticClass: "w-75 text-center text-wrapper" },
                          [_c("h1", [_vm._v(_vm._s(_vm.title))])]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex justify-content-center align-items-center my-5"
                          },
                          [_c("get-started-btn")],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "px-3" },
                          [
                            _c("sell-your-car-disclaimer", {
                              staticClass: "syc-disclaimer text-white"
                            })
                          ],
                          1
                        )
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-col",
                    {
                      staticClass: "px-lg-0 pl-md-0",
                      attrs: { cols: "12", md: "5" }
                    },
                    [
                      _c("b-img", {
                        staticClass: "d-none d-md-block image",
                        attrs: { fluid: "", src: _vm.sycImage }
                      }),
                      _vm._v(" "),
                      _c("b-img", {
                        staticClass: "d-md-none image",
                        attrs: { fluid: "", src: _vm.sycImageSm }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-col",
                    {
                      staticClass: "px-lg-0 pr-md-0 d-md-none",
                      attrs: { cols: "12", md: "7" }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "w-100 h-100 card" },
                        [
                          _c(
                            "div",
                            { staticClass: "p-20 text-center text-wrapper" },
                            [_c("h1", [_vm._v(_vm._s(_vm.title))])]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex justify-content-center align-items-center"
                            },
                            [_c("get-started-btn")],
                            1
                          ),
                          _vm._v(" "),
                          _c("sell-your-car-disclaimer", {
                            staticClass: "syc-disclaimer"
                          })
                        ],
                        1
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }