var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.deals.length > 0
    ? _c(
        "div",
        { staticClass: "top-deals container-fluid p-0" },
        [
          _c(
            "div",
            { staticClass: "header d-flex justify-content-center px-3" },
            [
              _c("h1", { staticClass: "title m-0" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.$t("component.topDeals.header.part1")) +
                    "\n            " +
                    _vm._s(_vm.$t("component.topDeals.header.part2")) +
                    "\n        "
                )
              ])
            ]
          ),
          _vm._v(" "),
          _vm._l(_vm.deals, function(deal) {
            return _c(
              "div",
              { key: deal.bodyStyle, staticClass: "deal" },
              [_c("deal-card", { attrs: { deal: deal } })],
              1
            )
          })
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }