<template>
    <div/>
</template>

<script>
import {get, call} from "vuex-pathify";
import _ from "lodash";
import geoLocationMixin from "@/mixins/geoLocationMixin";

export default {
    name: "GeoLocation",

    mixins: [geoLocationMixin],

    computed: {
        clientLocation: get("location/clientLocation"),
        selectedLocation: get("location/selectedLocation")
    },

    created() {
        if (!_.isNil(this.selectedLocation)) {
            return;
        }

        if (
            !_.isNil(this.clientLocation) &&
            !this.isExpired(this.clientLocation)
        ) {
            this.setLocation(this.clientLocation);
            return;
        }

        this.getLocation();

    },
    methods: {
        setLocation: call("location/setLocation"),
        loadLocation: call("location/loadLocation"),
        loadLocationByCoordinates: call("location/loadLocationByCoordinates"),
        getLocation() {
            this.$getLocation({
                timeout: 5000,
                maximumAge: 86400000 * 3 // 3 Days
            })
                .then(coordinates => {
                    const lat = _.get(coordinates, "lat");
                    const lng = _.get(coordinates, "lng");

                    if (_.isNil(lat) || _.isNil(lng)) {
                        this.loadLocation();
                    }

                    this.loadLocationByCoordinates({ lat, lng });
                })
                .catch(() => {
                    this.loadLocation();
                });
        }
    }
};
</script>
