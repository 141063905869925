<template>
    <b-row>
        <b-col cols="12" sm="" class="mb-2 mb-sm-0">
            <b-form-select
                v-model="selectedMake"
                :options="makeOptions"
                :class="{ selected: selectedMake }"
                :disabled="loading"
                @change="makeChanged"
            />
        </b-col>
        <b-col cols="12" sm="" class="mb-2 mb-sm-0">
            <b-form-select
                v-model="selectedModel"
                :options="modelOptions"
                :class="{ selected: selectedModel }"
                :disabled="loading"
                @change="modelChanged"
            />
        </b-col>
        <b-col
            v-if="bodyStyleOptions && bodyStyleOptions.length > 2"
            cols="12"
            sm=""
            class="mb-2 mb-sm-0"
        >
            <b-form-select
                v-model="selectedBodyStyle"
                :options="bodyStyleOptions"
                :class="{ selected: selectedBodyStyle }"
                :disabled="loading"
                @change="bodyStyleChanged"
            />
        </b-col>

        <b-col cols="12" sm="3">
            <search-button
                :loading="loading"
                :stock-type="stockType"
                :active="$options.name"
            />
        </b-col>
    </b-row>
</template>

<script>
import _ from "lodash";
import api from "@/api";
import { sync } from "vuex-pathify";
import SearchButton from "./SearchButton";
import { trackGAEvent } from "@/lib/GoogleTagManager";

export default {
    name: "MakeModel",

    components: { SearchButton },

    props: {
        stockType: {
            type: String,
            required: true
        }
    },

    data() {
        return {
            loading: true,
            selectedMake: null,
            selectedModel: null,
            selectedBodyStyle: null,
            makes: [],
            models: []
        };
    },

    computed: {
        updatedSelectedMake: sync("homeSearch/selectedMake"),
        updatedSelectedModel: sync("homeSearch/selectedModel"),
        updatedSelectedBodyStyle: sync("homeSearch/selectedBodyStyle"),

        isMakeSelected() {
            return !_.isNil(this.updatedSelectedMake);
        },
        isModelSelected() {
            return !_.isNil(this.updatedSelectedModel);
        },
        isBodyStyleSelected() {
            return !_.isNil(this.updatedSelectedBodyStyle);
        },

        makeOptions() {
            const makeOptions = [
                { value: null, text: "Select Make (ex. Toyota)" }
            ];

            if (_.size(this.makes) === 0) {
                return makeOptions;
            }

            const makeData = _.map(this.makes, make => {
                return {
                    value: make,
                    text: make.name
                };
            });

            return makeOptions.concat(makeData);
        },
        modelOptions() {
            const modelOptions = [
                { value: null, text: "Select Model (ex. Camry)" }
            ];

            if (_.size(this.models) === 0) {
                return modelOptions;
            }

            const modelData = _.map(this.models, model => {
                return {
                    value: model,
                    text: model.name
                };
            });

            return modelOptions.concat(modelData);
        },
        bodyStyleOptions() {
            const bodyStyleOptions = [{ value: null, text: "All Body Styles" }];

            const bodyStyleData = _.map(
                this.selectedModel?.bodyStyles,
                bodyStyle => {
                    return {
                        value: bodyStyle,
                        text: _.capitalize(bodyStyle)
                    };
                }
            );

            return bodyStyleOptions.concat(bodyStyleData);
        }
    },

    created() {
        this.fetchLatestMakes();
    },

    mounted() {
        if (this.isMakeSelected) {
            this.selectedMake = this.updatedSelectedMake;
        }
        if (this.isModelSelected) {
            this.selectedModel = this.updatedSelectedModel;
        }
        if (this.isBodyStyleSelected) {
            this.selectedBodyStyle = this.updatedSelectedBodyStyle;
        }
    },

    methods: {
        fetchLatestMakes() {
            this.loading = true;
            if (this.stockType === "NEW") {
                api.get("/vehicle-data/makes/latest")
                    .then(response => {
                        this.makes = response.data;

                        if (this.isMakeSelected) {
                            const minYear =
                                this.selectedMake.availableYears[0] ||
                                this.selectedMake.defaultYear;
                            const id = this.selectedMake.id;

                            this.fetchModels(minYear, id);
                        }

                        this.loading = false;
                    })
                    .catch(error => {
                        console.error(error);

                        this.loading = false;
                    });
            } else {
                const minYear = new Date().getFullYear() - 5;

                api.get("/vehicle-data/makes", { minimumYear: minYear })
                    .then(response => {
                        this.makes = response.data;

                        if (this.isMakeSelected) {
                            const id = this.selectedMake.id;
                            this.fetchModels(minYear, id);
                        }

                        this.loading = false;
                    })
                    .catch(error => {
                        console.error(error);

                        this.loading = false;
                    });
            }
        },
        fetchModels(year, makeId) {
            this.loading = true;

            if (this.stockType === "NEW") {
                api.get("/vehicle-data/models/search/byMinYear", {
                    minYear: year,
                    makeId
                })
                    .then(response => {
                        this.models = response.data;

                        this.loading = false;
                    })
                    .catch(error => {
                        console.error(error);

                        this.loading = false;
                    });
            } else {
                const minYear = new Date().getFullYear() - 5;

                api.get("/vehicle-data/models/search/byMinYear", {
                    minYear,
                    makeId
                })
                    .then(response => {
                        this.models = response.data;

                        this.loading = false;
                    })
                    .catch(error => {
                        console.error(error);

                        this.loading = false;
                    });
            }
        },
        makeChanged(make) {
            this.updatedSelectedMake = this.selectedMake;
            this.selectedModel = null;
            this.selectedBodyStyle = null;
            this.updatedSelectedModel = null;
            this.updatedSelectedBodyStyle = null;
            let minYear = null;

            if (!_.isNil(make)) {
                minYear =
                    _.min(_.get(make, "availableYears")) || make.defaultYear;
                this.fetchModels(minYear, make.id);
            }
        },
        modelChanged() {
            this.updatedSelectedModel = this.selectedModel;
        },
        bodyStyleChanged() {
            this.updatedSelectedBodyStyle = this.selectedBodyStyle;
        },
        trackGAEventMakeModel() {
            trackGAEvent(this, {
                category: "home-search",
                action: `search-${this.stockType}-make-model`,
                label: `${_.get(this.selectedMake, "name", "ANY")} - ${_.get(
                    this.selectedModel,
                    "name",
                    "ANY"
                )}`
            });
        }
    }
};
</script>
