var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "lifetime-warranty-block" } },
    [
      _c(
        "b-container",
        { staticClass: "p-sm-0", attrs: { fluid: "" } },
        [
          _c(
            "b-row",
            { staticClass: "content-card" },
            [
              _c(
                "b-col",
                {
                  staticClass:
                    "d-none d-sm-flex justify-content-center align-items-center",
                  attrs: { cols: "12", sm: "4" }
                },
                [
                  _c("b-img-lazy", {
                    directives: [{ name: "webp", rawName: "v-webp" }],
                    staticClass: "car-image",
                    attrs: {
                      src: require("./images/car.png"),
                      fluid: "",
                      alt: "Lifetime Warranty Car"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-col",
                {
                  staticClass: "p-0 d-flex flex-column justify-content-center",
                  attrs: { cols: "12", sm: "8" }
                },
                [
                  _c(
                    "h2",
                    { style: [_vm.isSpanish ? { fontSize: "48px" } : {}] },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(
                            _vm.$t("component.lifetimeWarrantyBlock.title")
                          ) +
                          "\n                "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("h3", { staticClass: "mb-3" }, [
                    _vm._v(
                      "\n                    " +
                        _vm._s(
                          _vm.$t("component.lifetimeWarrantyBlock.subTitle")
                        ) +
                        "\n                "
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "d-flex mb-2 mb-sm-0" }, [
                    _c(
                      "div",
                      { staticClass: "d-sm-none d-block mr-4" },
                      [
                        _c("b-img-lazy", {
                          directives: [{ name: "webp", rawName: "v-webp" }],
                          staticClass: "car-image",
                          attrs: {
                            src: require("./images/car.png"),
                            fluid: "",
                            alt: "Lifetime Warranty Car"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", [
                      _c("div", { staticClass: "mb-2" }, [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.$t("component.lifetimeWarrantyBlock.benefits")
                            )
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("ul", [
                        _c("li", [
                          _vm._v(
                            "\n                                " +
                              _vm._s(
                                _vm.$t("component.lifetimeWarrantyBlock.item1")
                              ) +
                              "\n                            "
                          )
                        ]),
                        _vm._v(" "),
                        _c("li", [
                          _vm._v(
                            "\n                                " +
                              _vm._s(
                                _vm.$t("component.lifetimeWarrantyBlock.item2")
                              ) +
                              "\n                            "
                          )
                        ]),
                        _vm._v(" "),
                        _c("li", [
                          _vm._v(
                            "\n                                " +
                              _vm._s(
                                _vm.$t("component.lifetimeWarrantyBlock.item3")
                              ) +
                              "\n                            "
                          )
                        ])
                      ])
                    ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "learn-more-button",
                    { on: { click: _vm.gotoWarrantyPage } },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(
                            _vm.$t("component.lifetimeWarrantyBlock.learnMore")
                          ) +
                          "\n                "
                      )
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-12 mt-3" },
                [_c("lifetime-warranty-disclaimer", { staticClass: "copy" })],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }