var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "mb-2" }, [
    _c(
      "div",
      {
        staticClass:
          "body-style-card px-2 d-flex flex-column justify-content-around h-100 mx-auto",
        class: { active: _vm.active },
        on: { click: _vm.click }
      },
      [
        _c("div", [_c("b-img", { attrs: { src: _vm.image, fluid: "" } })], 1),
        _vm._v(" "),
        _c("div", [_vm._t("default")], 2)
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }