<template>
    <div class="search-by-wrapper w-100 mb-2">
        <div
            class="search-by w-100 d-flex justify-content-between mb-2 mb-sm-1"
        >
            <div class="d-none d-sm-block">
                Shop by:
            </div>
            <b-link
                :class="{ active: isSelected('MAKE_MODEL') }"
                @click="select('MAKE_MODEL')"
            >
                <div class="d-flex flex-column">
                    <div>Make/Model</div>
                    <i
                        aria-hidden="true"
                        :class="isSelected('MAKE_MODEL') ? 'd-flex' : 'd-none'"
                        class="ion-android-arrow-dropdown"
                    />
                </div>
            </b-link>
            <b-link
                :class="{ active: isSelected('BODYSTYLE') }"
                @click="select('BODYSTYLE')"
            >
                <div class="d-flex flex-column">
                    <div>Bodystyle</div>
                    <i
                        aria-hidden="true"
                        :class="isSelected('BODYSTYLE') ? 'd-flex' : 'd-none'"
                        class="ion-android-arrow-dropdown"
                    />
                </div>
            </b-link>
            <b-link
                :class="{ active: isSelected('PRICE') }"
                @click="select('PRICE')"
            >
                <div class="d-flex flex-column">
                    <div>Price</div>
                    <i
                        aria-hidden="true"
                        :class="isSelected('PRICE') ? 'd-flex' : 'd-none'"
                        class="ion-android-arrow-dropdown"
                    />
                </div>
            </b-link>
            <b-link
                :class="{ active: isSelected('PAYMENT') }"
                @click="select('PAYMENT')"
            >
                <div class="d-flex flex-column">
                    <div>Payment</div>
                    <i
                        aria-hidden="true"
                        :class="isSelected('PAYMENT') ? 'd-flex' : 'd-none'"
                        class="ion-android-arrow-dropdown"
                    />
                </div>
            </b-link>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: String,
            required: false,
            default: "MAKE_MODEL"
        }
    },

    methods: {
        isSelected(value) {
            return this.value === value;
        },

        select(value) {
            this.$emit("input", value);
        }
    }
};
</script>

<style lang="scss" scoped>
.search-by-wrapper {
    @include media-breakpoint-up(sm) {
        border-bottom: solid 1px $muted-gray;
    }

    .search-by {
        max-width: 380px;
        font-size: px2rem(14);
        font-family: $helvetica-56-italic;
        font-style: italic;
        color: #717171;

        a {
            color: #717171;

            &.active {
                font-family: $helvetica-55-Roman;
                color: $primary-blue;
                font-style: normal;
                text-decoration: none;
            }

            .ion-android-arrow-dropdown {
                height: 5px;
                font-size: 16px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
}
</style>
