<template>
    <div class="hero">
        <carsaver-at-walmart-hero
            v-if="enabledHeroComponent === 'carsaverAtWalmartHero'"
        />
        <shop-hero v-if="enabledHeroComponent === 'shopHero'" />
    </div>
</template>

<script>
import { get } from "vuex-pathify";
import CarsaverAtWalmartHero from "Modules/Home/components/Hero/components/CarsaverAtWalmartHero/index";
import ShopHero from "Modules/Home/components/Hero/components/ShopHero/index";

export default {
    name: "HomeHero",
    components: { ShopHero, CarsaverAtWalmartHero },
    computed: {
        campaignType: get("campaign/type"),

        enabledHeroComponent() {
            const heros = {
                carsaver: "carsaverAtWalmartHero",
                shop: "shopHero"
            };

            switch (this.campaignType) {
                case "carsaver":
                    return heros.carsaver;
                case "shop":
                    return heros.shop;
                default:
                    return heros.carsaver;
            }
        }
    }
};
</script>
