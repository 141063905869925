<template>
    <learn-more-button
        :disabled="isDisabled"
        :variant="variant"
        :size="size"
        @click="handleClick"
    >
        <span v-if="!loading">
            <slot />
        </span>
        <div v-if="loading" class="d-flex justify-content-center">
            <loading-spinner :size="30" />
        </div>
    </learn-more-button>
</template>

<script>
import LearnMoreButton from "Modules/Home/components/LearnMoreButton/index";
import LoadingSpinner from "Components/LoadingSpinner/index";

export default {
    name: "LoadingBtn",
    components: { LearnMoreButton, LoadingSpinner },
    props: {
        loading: {
            type: Boolean,
            required: true
        },
        disabled: {
            type: Boolean,
            default: false
        },
        variant: {
            type: String,
            default: "primary"
        },
        size: {
            type: String,
            default: ""
        }
    },

    computed: {
        isDisabled() {
            return this.disabled || this.loading;
        }
    },

    methods: {
        handleClick: function(e) {
            this.$emit("click", e);
        }
    }
};
</script>
