<template>
    <div id="todays-top-deals">
        <content-card>
            <div
                class="col-lg-5 d-flex flex-column justify-content-center headline"
            >
                <h1 class="mb-0">
                    Today's Top Deals
                </h1>
                <p class="location">
                    <span v-if="location">
                        {{ $t("component.todaysTopDeals.for") }}
                        {{ cityState }}
                        <b-link class="font-italic" @click="editLocation()">
                            {{ $t("component.todaysTopDeals.edit") }}
                        </b-link>
                    </span>
                    <span v-else>
                        &nbsp;
                    </span>
                </p>
            </div>
            <div v-if="showSavings" class="col-lg-7 actions">
                <div class="row">
                    <div
                        class="col-sm-4 d-flex flex-column justify-content-center align-items-center action"
                        :class="{ 'px-0': isSpanish }"
                    >
                        <div class="label mb-1 mb-sm-0">
                            {{ $t("component.todaysTopDeals.biggestSavings") }}
                            <tooltip-disclaimer
                                v-if="biggestSavings"
                                :title="biggestSavings.disclaimer"
                            />
                        </div>
                        <loading-btn
                            :loading="loadingAll"
                            variant="outline-primary"
                            size="lg"
                            :style="[isSpanish ? { fontSize: '15px' } : null]"
                            :class="{ 'p-0': isSpanish }"
                            @click="() => gotoNewSortedBy('savings,desc')"
                        >
                            {{
                                biggestSavings
                                    | emptyDash("value")
                                    | numeral("$0,0")
                            }}
                            {{ $t("component.todaysTopDeals.offMsrp") }}
                        </loading-btn>
                    </div>
                    <div
                        class="col-sm-4 d-flex flex-column justify-content-center align-items-center action"
                        :class="{ 'px-0': isSpanish }"
                    >
                        <div class="label mb-1 mb-sm-0">
                            {{ $t("component.todaysTopDeals.lowestPrice") }}
                            <tooltip-disclaimer
                                v-if="lowestPrice"
                                :title="lowestPrice.disclaimer"
                            />
                        </div>
                        <loading-btn
                            :loading="loadingAll"
                            variant="outline-primary"
                            size="lg"
                            @click="() => gotoNewSortedBy('price,asc')"
                        >
                            {{
                                lowestPrice
                                    | emptyDash("value")
                                    | numeral("$0,0")
                            }}
                            {{ newOrUsed }}
                        </loading-btn>
                    </div>
                    <div
                        class="col-sm-4 d-flex flex-column justify-content-center align-items-center action"
                        :class="{ 'px-0': isSpanish }"
                    >
                        <div class="label mb-1 mb-sm-0">
                            {{ $t("component.todaysTopDeals.lowestPayment") }}
                            <tooltip-disclaimer
                                v-if="lowestPayment"
                                :title="lowestPayment.disclaimer"
                            />
                        </div>
                        <loading-btn
                            :loading="loadingAll"
                            variant="outline-primary"
                            size="lg"
                            @click="
                                () =>
                                    gotoNewSortedBy('quote.monthlyPayment,asc')
                            "
                        >
                            {{
                                lowestPayment
                                    | emptyDash("value")
                                    | numeral("$0")
                            }}{{ $t("component.todaysTopDeals.mo") }}
                            {{ financeOrLease }}
                        </loading-btn>
                    </div>
                </div>
            </div>
            <div
                v-if="showComingSoon"
                class="col-lg-7 coming-soon d-flex flex-column justify-content-center text-lg-left text-center pt-lg-0 pt-3"
            >
                <div class="d-flex justify-content-center">
                    <b-button
                        variant="outline-primary"
                        size="xlg"
                        class="mx-3"
                        href="/pick-your-flow"
                    >
                        Search Local Listings
                    </b-button>
                </div>
            </div>

            <div v-if="showLocationEditor" class="col-lg-7 unknown-location">
                <p>{{ $t("component.todaysTopDeals.enterZip") }}</p>
                <b-form @submit.prevent="saveZipCode">
                    <b-container>
                        <div class="row">
                            <div class="col-sm-8 mb-sm-0 mb-3 mr-1">
                                <b-form-input
                                    v-model="zipCode"
                                    type="text"
                                    size="lg"
                                    :placeholder="zipCodePlaceholder"
                                    :state="isValidZipCode"
                                    maxlength="5"
                                />
                            </div>
                            <div class="col-sm-3 ml-1">
                                <b-button
                                    type="submit"
                                    variant="primary"
                                    block
                                    size="lg"
                                    class="d-block"
                                >
                                    {{ $t("component.todaysTopDeals.save") }}
                                </b-button>
                            </div>
                        </div>
                        <p v-if="showError" class="text-danger">
                            {{ $t("component.todaysTopDeals.zipCodeNotFound") }}
                        </p>
                    </b-container>
                </b-form>
            </div>
        </content-card>
    </div>
</template>

<style lang="scss">
#todays-top-deals {
    margin: 0;

    @include media-breakpoint-up(sm) {
        margin: 10px 0 0 0;

        .container-fluid {
            .content-card {
                background: $white !important;

                .headline {
                    h1 {
                        font-size: px2rem(45);
                        line-height: 50px;
                        margin-bottom: 5px;
                    }
                }
                button {
                }

                a.btn-outline-primary {
                    width: 195px !important;
                }
            }
        }

        [class*="col-"] {
            padding-right: 15px;
            padding-left: 15px;
        }
    }

    .container-fluid {
        .content-card {
            h1 {
                font-size: 26px;
                line-height: 35px;
            }

            .location {
                font-size: px2rem(16);
                line-height: 25px;
                text-shadow: 0 6px 25px 0 rgba(0, 0, 0, 0.5);
            }

            h2 {
                color: $primary-blue;
            }

            .headline {
                p {
                    margin-bottom: 0;
                }
            }

            a.btn-outline-primary {
                width: 275px;
            }

            .actions {
                padding-top: 5px;

                .label {
                    font-family: $helvetica-55-Roman;
                    color: $primary-dark-blue;
                    font-size: px2rem(16);

                    a {
                        outline: none;
                    }
                }

                .action {
                    margin: 5px 0;
                }
            }
        }
    }

    [class*="col-"] {
        padding-right: 0;
        padding-left: 0;
    }
}
</style>

<script>
import { get, call } from "vuex-pathify";

import _ from "lodash";
import ContentCard from "Modules/Home/components/ContentCard/index";
import LoadingBtn from "Modules/Home/components/LoadingBtn/index";
import TooltipDisclaimer from "Components/TooltipDisclaimer/index";

export default {
    name: "TodaysTopDeals",

    components: {
        TooltipDisclaimer,
        ContentCard,
        LoadingBtn
    },
    data() {
        return {
            zipCode: "",
            showLocationEditor: false,
            isValidZipCode: null,
            zipCodePlaceholder: this.$t("component.todaysTopDeals.zipCode")
        };
    },
    computed: {
        financeOrLease() {
            const financeOrLease = _.get(this.lowestPayment, "type", null);
            if (financeOrLease === null) {
                return "- -";
            }

            if (_.lowerCase(financeOrLease) === "lease") {
                return this.$t("component.todaysTopDeals.lease");
            }

            if (_.lowerCase(financeOrLease) === "finance") {
                return this.$t("component.todaysTopDeals.finance");
            }

            return "- -";
        },

        newOrUsed() {
            const newOrUsed = _.get(this.lowestPrice, "type", null);
            if (newOrUsed === null) {
                return "- -";
            }

            if (_.lowerCase(newOrUsed) === "new") {
                return this.$t("component.todaysTopDeals.new");
            }

            if (_.lowerCase(newOrUsed) === "used") {
                return this.$t("component.todaysTopDeals.used");
            }

            return "- -";
        },

        isSpanish() {
            return this.lang === "es";
        },

        showError() {
            return (
                this.fetched === true &&
                this.location === null &&
                !this.isLoading &&
                this.zipCode !== ""
            );
        },

        showSavings() {
            return (
                this.loadingAll ||
                (!this.showError &&
                    !this.showComingSoon &&
                    !this.showLocationEditor)
            );
        },

        showComingSoon() {
            const biggestSavings = _.get(this.biggestSavings, "value", null);
            return (
                this.fetched &&
                this.location !== null &&
                biggestSavings === null &&
                !this.isLoading &&
                !this.showLocationEditor
            );
        },

        loadingAll() {
            return this.isLoading === true || this.locationLoading === true;
        },

        cityState() {
            if (_.isNil(this.location)) {
                return null;
            }

            return `${this.location.city}, ${this.location.state}`;
        },

        lang: get("translated/lang"),
        location: get("location/selectedLocation"),
        zipCodeLookupLoadState: get("location/loader"),
        locationLoading: get("location/loader@isLoading"),
        biggestSavings: get("topdeals/top@biggestSavings"),
        lowestPrice: get("topdeals/top@lowestPrice"),
        lowestPayment: get("topdeals/top@lowestPayment"),
        isLoading: get("topdeals/top@isLoading"),
        fetched: get("topdeals/top@fetched"),
        userStateCode: get("user/user@stateCode"),
        userCity: get("user/user@city")
    },
    watch: {
        location(value) {
            const zipCode = _.get(value, "zipCode", null);
            if (!_.isNil(zipCode)) {
                this.loadTopDeals(zipCode);
            }
        },
        zipCodeLookupLoadState(newState) {
            if (
                this.showLocationEditor &&
                newState.isComplete &&
                !newState.isError
            ) {
                this.hideLocationEditor();
            } else if (
                !this.showLocationEditor &&
                newState.isComplete &&
                newState.isError
            ) {
                this.editLocation();
            }

            if (this.zipCode === "") {
                this.isValidZipCode = null;
            } else {
                this.isValidZipCode = !newState.isError;
            }
        }
    },

    created() {
        const zipCode = _.get(this.location, "zipCode", null);
        if (!_.isNil(zipCode)) {
            this.loadTopDeals(zipCode);
        }
    },

    methods: {
        loadTopDeals: call("topdeals/loadTopDeals"),
        updateZipCode: call("location/updateZipCode"),
        gotoNewSortedBy(sortBy) {
            document.location = `/shop/models?sort=${sortBy}&distance=50`;
        },
        editLocation() {
            this.showLocationEditor = true;
        },
        hideLocationEditor() {
            this.showLocationEditor = false;
        },
        isValidZipCodePattern(zipCode) {
            return /(^\d{5}$)/.test(zipCode);
        },
        saveZipCode() {
            if (this.isValidZipCodePattern(this.zipCode)) {
                this.hideLocationEditor();
                this.updateZipCode(this.zipCode);
            } else {
                this.isValidZipCode = false;
            }

            // Kiosk requires an explicit return of false - even with .prevent on the event.
            return false;
        }
    }
};
</script>
