<template>
    <b-container class="px-0">
        <b-row>
            <b-col cols="12" class="d-sm-none">
                <hr class="mt-0" />
            </b-col>
            <b-col cols="4" sm="3">
                <body-style-card
                    :image="suv"
                    :active="isActive('suv')"
                    @click="toggle('suv')"
                >
                    SUVs / Crossovers
                </body-style-card>
            </b-col>
            <b-col cols="4" sm="3">
                <body-style-card
                    :image="truck"
                    :active="isActive('truck')"
                    @click="toggle('truck')"
                >
                    Pickup Truck
                </body-style-card>
            </b-col>
            <b-col cols="4" sm="3">
                <body-style-card
                    :image="sedan"
                    :active="isActive('sedan')"
                    @click="toggle('sedan')"
                >
                    Sedans
                </body-style-card>
            </b-col>
            <b-col cols="4" sm="3">
                <body-style-card
                    :image="hatchback"
                    :active="isActive('hatchback')"
                    @click="toggle('hatchback')"
                >
                    Hatchbacks
                </body-style-card>
            </b-col>
            <b-col cols="4" sm="3">
                <body-style-card
                    :image="coupe"
                    :active="isActive('coupe')"
                    @click="toggle('coupe')"
                >
                    Coupes
                </body-style-card>
            </b-col>
            <b-col cols="4" sm="3">
                <body-style-card
                    :image="convertible"
                    :active="isActive('convertible')"
                    @click="toggle('convertible')"
                >
                    Convertibles
                </body-style-card>
            </b-col>
            <b-col cols="4" sm="3">
                <body-style-card
                    :image="wagon"
                    :active="isActive('wagon')"
                    @click="toggle('wagon')"
                >
                    Wagons
                </body-style-card>
            </b-col>
            <b-col cols="4" sm="3">
                <body-style-card
                    :image="van"
                    :active="isActive('van')"
                    @click="toggle('van')"
                >
                    Vans
                </body-style-card>
            </b-col>
        </b-row>
        <b-row class="justify-content-center">
            <b-col sm="4" class="mb-2 mb-sm-0 col">
                <b-form-select
                    v-model="selectedMinPrice"
                    :options="minPriceOptions"
                    :class="{ selected: selectedMinPrice }"
                    @change="minPriceChanged"
                />
            </b-col>
            <div class="to d-flex align-items-center mb-2 mb-sm-0">
                to
            </div>
            <b-col sm="4" class="mb-2 mb-sm-0 col">
                <b-form-select
                    v-model="selectedMaxPrice"
                    :options="maxPriceOptions"
                    :class="{ selected: selectedMaxPrice }"
                    @change="maxPriceChanged"
                />
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12" class="mt-2">
                <search-button
                    style="display: initial;"
                    :stock-type="stockType"
                    :active="$options.name"
                />
            </b-col>
        </b-row>
    </b-container>
</template>

<style lang="scss" scoped>
.to {
    color: #717171;
    font-family: $helvetica-56-italic;
    font-style: italic;
    font-size: px2rem(14);
}
</style>

<script>
import _ from "lodash";
import BodyStyleCard from "./BodyStyleCard";
import ConvertibleImage from "./images/bodystyles_convertibles.png";
import CoupeImage from "./images/bodystyles_coupes.png";
import HatchbackImage from "./images/bodystyles_hatchbacks.png";
import SedanImage from "./images/bodystyles_sedans.png";
import SuvImage from "./images/bodystyles_suvs.png";
import TruckImage from "./images/bodystyles_trucks.png";
import VanImage from "./images/bodystyles_vans.png";
import WagonImage from "./images/bodystyles_wagons.png";
import ConvertibleImageSmall from "./images/bodystyles_convertibles_sm.png";
import CoupeImageSmall from "./images/bodystyles_coupes_sm.png";
import HatchbackImageSmall from "./images/bodystyles_hatchbacks_sm.png";
import SedanImageSmall from "./images/bodystyles_sedans_sm.png";
import SuvImageSmall from "./images/bodystyles_suvs_sm.png";
import TruckImageSmall from "./images/bodystyles_trucks_sm.png";
import VanImageSmall from "./images/bodystyles_vans_sm.png";
import WagonImageSmall from "./images/bodystyles_wagons_sm.png";
import numeral from "numeral";
import { sync } from "vuex-pathify";
import SearchButton from "./SearchButton";
import { trackGAEvent } from "@/lib/GoogleTagManager";

export default {
    name: "BodyStyle",
    components: {
        SearchButton,
        BodyStyleCard
    },
    props: {
        stockType: {
            type: String,
            required: true
        }
    },

    data() {
        return {
            convertibleImage: ConvertibleImage,
            coupeImage: CoupeImage,
            hatchbackImage: HatchbackImage,
            sedanImage: SedanImage,
            suvImage: SuvImage,
            truckImage: TruckImage,
            vanImage: VanImage,
            wagonImage: WagonImage,
            convertibleImageSmall: ConvertibleImageSmall,
            coupeImageSmall: CoupeImageSmall,
            hatchbackImageSmall: HatchbackImageSmall,
            sedanImageSmall: SedanImageSmall,
            suvImageSmall: SuvImageSmall,
            truckImageSmall: TruckImageSmall,
            vanImageSmall: VanImageSmall,
            wagonImageSmall: WagonImageSmall,
            selectedBodyStyles: [],
            selectedMinPrice: null,
            selectedMaxPrice: null
        };
    },

    mounted() {
        if (this.areBodyStylesSelected) {
            this.selectedBodyStyles = this.updatedSelectedBodyStyles;
        }
        if (this.isMinPriceSelected) {
            this.selectedMinPrice = this.updatedSelectedMinPrice;
        }
        if (this.isMaxPriceSelected) {
            this.selectedMaxPrice = this.updatedSelectedMaxPrice;
        }
    },

    computed: {
        updatedSelectedBodyStyles: sync("homeSearch/selectedBodyStyles"),
        updatedSelectedMinPrice: sync("homeSearch/minPrice"),
        updatedSelectedMaxPrice: sync("homeSearch/maxPrice"),

        areBodyStylesSelected() {
            return (
                !_.isNil(this.updatedSelectedBodyStyles) &&
                !_.isEmpty(this.updatedSelectedBodyStyles)
            );
        },
        isMinPriceSelected() {
            return !_.isNil(this.updatedSelectedMinPrice);
        },
        isMaxPriceSelected() {
            return !_.isNil(this.updatedSelectedMaxPrice);
        },

        convertible() {
            if (this.screenSize === "sm" || this.screenSize === "xs") {
                return this.convertibleImageSmall;
            } else {
                return this.convertibleImage;
            }
        },
        coupe() {
            if (this.screenSize === "sm" || this.screenSize === "xs") {
                return this.coupeImageSmall;
            } else {
                return this.coupeImage;
            }
        },
        hatchback() {
            if (this.screenSize === "sm" || this.screenSize === "xs") {
                return this.hatchbackImageSmall;
            } else {
                return this.hatchbackImage;
            }
        },
        sedan() {
            if (this.screenSize === "sm" || this.screenSize === "xs") {
                return this.sedanImageSmall;
            } else {
                return this.sedanImage;
            }
        },
        suv() {
            if (this.screenSize === "sm" || this.screenSize === "xs") {
                return this.suvImageSmall;
            } else {
                return this.suvImage;
            }
        },
        truck() {
            if (this.screenSize === "sm" || this.screenSize === "xs") {
                return this.truckImageSmall;
            } else {
                return this.truckImage;
            }
        },
        van() {
            if (this.screenSize === "sm" || this.screenSize === "xs") {
                return this.vanImageSmall;
            } else {
                return this.vanImage;
            }
        },
        wagon() {
            if (this.screenSize === "sm" || this.screenSize === "xs") {
                return this.wagonImageSmall;
            } else {
                return this.wagonImage;
            }
        },
        screenSize() {
            return this.$mq;
        },
        minPriceOptions() {
            const options = [];

            options.push({ value: null, text: "Min: Any" });

            _.range(2000, 10000, 2000).forEach(price => {
                options.push({
                    value: price,
                    text: numeral(price).format("$0,0")
                });
            });

            _.range(10000, 50000, 5000).forEach(price => {
                options.push({
                    value: price,
                    text: numeral(price).format("$0,0")
                });
            });

            _.range(50000, 200000, 25000).forEach(price => {
                options.push({
                    value: price,
                    text: numeral(price).format("$0,0")
                });
            });

            return options;
        },
        maxPriceOptions() {
            const options = [];

            _.range(4000, 10000, 2000).forEach(price => {
                options.push({
                    value: price,
                    text: numeral(price).format("$0,0")
                });
            });

            _.range(10000, 50000, 5000).forEach(price => {
                options.push({
                    value: price,
                    text: numeral(price).format("$0,0")
                });
            });

            _.range(50000, 200000, 25000).forEach(price => {
                options.push({
                    value: price,
                    text: numeral(price).format("$0,0")
                });
            });

            options.push({ value: null, text: "Max: Any" });

            return options;
        }
    },

    methods: {
        minPriceChanged(minPrice) {
            if (minPrice >= this.selectedMaxPrice) {
                this.selectedMaxPrice = null;
                this.updatedSelectedMaxPrice = null;
            }
            this.updatedSelectedMinPrice = minPrice;
        },
        maxPriceChanged(maxPrice) {
            if (maxPrice <= this.selectedMinPrice) {
                this.selectedMinPrice = null;
                this.updatedSelectedMinPrice = null;
            }
            this.updatedSelectedMaxPrice = maxPrice;
        },

        isActive(bodyStyle) {
            return _.indexOf(this.selectedBodyStyles, bodyStyle) !== -1;
        },

        toggle(bodyStyle) {
            if (this.isActive(bodyStyle)) {
                this.selectedBodyStyles = _.remove(
                    this.selectedBodyStyles,
                    b => b !== bodyStyle
                );
            } else {
                this.selectedBodyStyles.push(bodyStyle);
            }
            this.updatedSelectedBodyStyles = [...this.selectedBodyStyles];
        },
        trackGAEventBodyStyle() {
            trackGAEvent(this, {
                category: "home-search",
                action: `search-${this.stockType}-body-styles`,
                label: `${_.join(this.selectedBodyStyles.sort())}: ${this
                    .selectedMinPrice || "ANY"} - ${this.selectedMaxPrice ||
                    "ANY"}`
            });
        }
    }
};
</script>
