var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "certified-dealers-block" } },
    [
      _c(
        "b-container",
        { staticClass: "p-sm-0", attrs: { fluid: "" } },
        [
          _c("b-row", { staticClass: "content-card" }, [
            _c(
              "div",
              {
                staticClass:
                  "p-0 p-sm-3 col-md-6 offset-md-6 d-flex flex-column justify-content-end"
              },
              [
                _c("h2", [
                  _vm._v(_vm._s(_vm.$t("component.certifiedDealers.header")))
                ]),
                _vm._v(" "),
                _c("h3", { staticClass: "mb-4" }, [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.$t("component.certifiedDealers.subHeader")) +
                      "\n                "
                  )
                ]),
                _vm._v(" "),
                _c("ul", [
                  _c("li", [
                    _vm._v(_vm._s(_vm.$t("component.certifiedDealers.item1")))
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _vm._v(_vm._s(_vm.$t("component.certifiedDealers.item2")))
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _vm._v(_vm._s(_vm.$t("component.certifiedDealers.item3")))
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _vm._v(_vm._s(_vm.$t("component.certifiedDealers.item4")))
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _vm._v(_vm._s(_vm.$t("component.certifiedDealers.item5")))
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _vm._v(_vm._s(_vm.$t("component.certifiedDealers.item6")))
                  ])
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex d-flex justify-content-center justify-content-sm-start"
                  },
                  [
                    _c(
                      "learn-more-button",
                      { on: { click: _vm.gotoDealerOverviewPage } },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(
                              _vm.$t("component.certifiedDealers.learnMore")
                            ) +
                            "\n                    "
                        )
                      ]
                    )
                  ],
                  1
                )
              ]
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }