var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("p", { staticClass: "disclaimer" }, [
    _vm._v(" " + _vm._s(_vm.$t("disclaimer.syc")) + " ")
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }