var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "shop-hero-block" }, [
    _c(
      "div",
      {
        staticClass:
          "shop-hero hero-full-height bg-img d-flex align-items-end justify-content-between"
      },
      [
        _c(
          "div",
          { staticClass: "text-wrapper d-none d-md-block" },
          [
            _c("h1", { staticClass: "title" }, [
              _vm._v(
                "\n                All New Auto-Buying Program\n            "
              )
            ]),
            _vm._v(" "),
            _c("hr"),
            _vm._v(" "),
            _c(
              "svg-fallback",
              { staticClass: "shop-logo", attrs: { src: _vm.logoImgSvg } },
              [_c("img", { attrs: { alt: "Shop.com", src: _vm.logoImgPng } })]
            )
          ],
          1
        ),
        _vm._v(" "),
        _vm._m(0)
      ]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "shop-cta-block d-flex align-items-center" }, [
      _c(
        "div",
        {
          staticClass:
            "text-wrapper d-flex d-sm-none align-items-center justify-content-center"
        },
        [
          _vm._m(1),
          _vm._v(" "),
          _c(
            "svg-fallback",
            { staticClass: "shop-logo", attrs: { src: _vm.logoImgSvg } },
            [_c("img", { attrs: { alt: "Shop.com", src: _vm.logoImgPng } })]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "button-wrapper d-flex flex-column justify-content-center align-items-center w-100"
            },
            [
              _c(
                "a",
                {
                  staticClass: "btn btn-primary btn-xlg",
                  attrs: { href: "/shop-by/payment?paymentType=LEASE" }
                },
                [_vm._v("Shop Leases")]
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "btn btn-very-dark-blue btn-xlg",
                  attrs: { href: "/shop-by" }
                },
                [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.$t("page.home.floatingFooter.new")) +
                      "\n                "
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "btn btn-dark-blue btn-xlg",
                  attrs: { href: "/shop/used" }
                },
                [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.$t("page.home.floatingFooter.used")) +
                      "\n                "
                  )
                ]
              )
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "button-wrapper d-none d-sm-flex justify-content-center align-items-center w-100"
        },
        [
          _c(
            "a",
            {
              staticClass: "btn btn-primary btn-xlg mr-3",
              attrs: { href: "/shop-by/payment?paymentType=LEASE" }
            },
            [_vm._v("Shop Leases")]
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "btn btn-very-dark-blue btn-xlg mr-3",
              attrs: { href: "/shop-by" }
            },
            [
              _vm._v(
                "\n                " +
                  _vm._s(_vm.$t("page.home.floatingFooter.new")) +
                  "\n            "
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "btn btn-dark-blue btn-xlg",
              attrs: { href: "/shop/used" }
            },
            [
              _vm._v(
                "\n                " +
                  _vm._s(_vm.$t("page.home.floatingFooter.used")) +
                  "\n            "
              )
            ]
          )
        ]
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "logo-wrapper d-none d-md-block" }, [
      _c("img", {
        staticClass: "logo-img",
        attrs: { src: require("./images/cs_logo_white.png"), alt: "CarSaver" }
      })
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("h1", { staticClass: "title" }, [
      _vm._v("\n                All New "),
      _c("br"),
      _vm._v("\n                Auto-Buying Program\n            ")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }