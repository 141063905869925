var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "deal-card d-flex flex-column d-md-block" }, [
    _c(
      "a",
      {
        staticClass: "image-wrapper d-flex justify-content-center",
        attrs: { href: _vm.urls.listings }
      },
      [
        _c("b-img-lazy", {
          directives: [{ name: "webp", rawName: "v-webp" }],
          attrs: { src: _vm.urls.img, alt: _vm.deal.bodyStyle }
        })
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass:
          "header p-3 pt-md-4 pl-md-4 pr-md-4 d-flex justify-content-between d-md-block"
      },
      [
        _c("h3", [
          _vm._v(
            _vm._s(_vm.$t("component.topDeals.bodyTypes." + _vm.deal.bodyStyle))
          )
        ]),
        _vm._v(" "),
        _c("p", [
          _c("span", { staticClass: "count" }, [
            _vm._v(
              "\n                " +
                _vm._s(_vm._f("numeral")(_vm.deal.inventoryCount, "0,0")) +
                "\n            "
            )
          ]),
          _vm._v(
            "\n            " +
              _vm._s(_vm.$t("component.topDeals.card.subtitles")) +
              "\n        "
          )
        ])
      ]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "deal-pricing-block-wrapper" }, [
      _c(
        "div",
        { staticClass: "deal-pricing-block d-flex flex-column pr-4 pl-4 pb-4" },
        [
          _c(
            "div",
            {
              staticClass: "pricing-tabs-wrapper mb-4",
              on: { click: _vm.clickHandler }
            },
            [
              _c(
                "div",
                { staticClass: "pricing-tabs d-flex justify-content-between" },
                [
                  !_vm.averageSavingsBelowZero
                    ? _c(
                        "div",
                        {
                          staticClass: "tab",
                          class: { active: _vm.savingsActive },
                          attrs: {
                            "aria-pressed": _vm.savingsActive,
                            name: "savings-tab"
                          }
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.$t("component.topDeals.savings")) +
                              "\n\n                        "
                          ),
                          _vm._m(0)
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "tab",
                      class: { active: _vm.priceActive },
                      attrs: {
                        "aria-pressed": _vm.priceActive,
                        name: "price-tab"
                      }
                    },
                    [
                      _vm._v(
                        "\n                        " +
                          _vm._s(_vm.$t("component.topDeals.price")) +
                          "\n\n                        "
                      ),
                      _vm._m(1)
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "tab",
                      class: { active: _vm.paymentActive },
                      attrs: {
                        "aria-pressed": _vm.paymentActive,
                        name: "payment-tab"
                      }
                    },
                    [
                      _vm._v(
                        "\n                        " +
                          _vm._s(_vm.$t("component.topDeals.payment")) +
                          "\n\n                        "
                      ),
                      _vm._m(2)
                    ]
                  )
                ]
              ),
              _vm._v(" "),
              _vm.savingsActive
                ? _c("div", { staticClass: "savings-wrapper" }, [
                    _c(
                      "p",
                      { staticClass: "title" },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(
                              _vm.$t("component.topDeals.averageSavings")
                            ) +
                            "\n                        "
                        ),
                        _vm.deal.averageSavings
                          ? _c("tooltip-disclaimer", {
                              attrs: {
                                title: _vm.deal.averageSavings.disclaimer,
                                "is-deal-card": ""
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("p", { staticClass: "center-context" }, [
                      _c("sup", [_vm._v("$")]),
                      _vm._v(
                        _vm._s(
                          _vm._f("currencyFormatter")(
                            _vm.deal.averageSavings.value
                          )
                        ) + "\n                    "
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "p",
                      { staticClass: "sub-title" },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(_vm.$t("component.topDeals.maxSavings")) +
                            " $" +
                            _vm._s(
                              _vm._f("currencyFormatter")(
                                _vm.deal.biggestSavings.value
                              )
                            ) +
                            "\n                        "
                        ),
                        _vm.deal.biggestSavings
                          ? _c("tooltip-disclaimer", {
                              attrs: {
                                title: _vm.deal.biggestSavings.disclaimer,
                                "is-deal-card": ""
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.priceActive
                ? _c("div", { staticClass: "price-wrapper" }, [
                    _c(
                      "p",
                      { staticClass: "title" },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(_vm.$t("component.topDeals.asLowAs")) +
                            "\n                        "
                        ),
                        _vm.deal.lowestPrice
                          ? _c("tooltip-disclaimer", {
                              attrs: {
                                title: _vm.deal.lowestPrice.disclaimer,
                                "is-deal-card": ""
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("p", { staticClass: "center-context" }, [
                      _c("sup", [_vm._v("$")]),
                      _vm._v(
                        _vm._s(
                          _vm._f("currencyFormatter")(
                            _vm.deal.lowestPrice.value
                          )
                        ) + "\n                    "
                      )
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "sub-title" }, [
                      _vm._v(
                        "\n                        " +
                          _vm._s(_vm.$t("component.topDeals.plusFees")) +
                          "\n                    "
                      )
                    ])
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.paymentActive
                ? _c("div", { staticClass: "payment-wrapper" }, [
                    _c(
                      "p",
                      { staticClass: "title" },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(_vm.$t("component.topDeals.starting")) +
                            "\n                        "
                        ),
                        _vm.deal.lowestPayment
                          ? _c("tooltip-disclaimer", {
                              attrs: {
                                title: _vm.deal.lowestPayment.disclaimer,
                                "is-deal-card": ""
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("p", { staticClass: "center-context" }, [
                      _c("sup", [_vm._v("$")]),
                      _vm._v(
                        _vm._s(
                          _vm._f("currencyFormatter")(
                            _vm.deal.lowestPayment.value
                          )
                        )
                      ),
                      _c("sub", [
                        _vm._v(
                          "/" + _vm._s(_vm.$t("component.topDeals.abbr.month"))
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _vm.isFinance
                      ? _c("p", { staticClass: "sub-title" }, [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.$t("component.topDeals.finance")) +
                              "\n                    "
                          )
                        ])
                      : _vm.isLease
                      ? _c("p", { staticClass: "sub-title" }, [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.$t("component.topDeals.lease")) +
                              "\n                    "
                          )
                        ])
                      : _vm._e()
                  ])
                : _vm._e()
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "view-deals-btn-wrapper d-flex justify-content-center d-md-block"
            },
            [
              _c(
                "a",
                {
                  staticClass:
                    "view-deals-btn d-none d-md-block btn btn-primary btn-lg",
                  attrs: { href: _vm.viewDetailsUrl }
                },
                [
                  _vm._v(
                    "\n                    " +
                      _vm._s(
                        _vm._f("capitalize")(
                          _vm.$t("component.topDeals.viewDeals")
                        )
                      ) +
                      "\n                "
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass:
                    "mobile-view-deals-btn view-deals-btn d-block d-md-none btn btn-outline-primary btn-lg",
                  attrs: { href: _vm.viewDetailsUrl }
                },
                [
                  _vm._v(
                    "\n                    " +
                      _vm._s(
                        _vm._f("capitalize")(
                          _vm.$t("component.topDeals.viewDeals")
                        )
                      ) +
                      "\n                "
                  )
                ]
              )
            ]
          )
        ]
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "border-block d-flex" }, [
      _c("span", { staticClass: "part" }),
      _vm._v(" "),
      _c("span", { staticClass: "caret" }),
      _vm._v(" "),
      _c("span", { staticClass: "part" })
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "border-block d-flex" }, [
      _c("span", { staticClass: "part" }),
      _vm._v(" "),
      _c("span", { staticClass: "caret" }),
      _vm._v(" "),
      _c("span", { staticClass: "part" })
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "border-block d-flex" }, [
      _c("span", { staticClass: "part" }),
      _vm._v(" "),
      _c("span", { staticClass: "caret" }),
      _vm._v(" "),
      _c("span", { staticClass: "part" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }