<template>
    <b-row>
        <b-col class="mb-2 mb-sm-0">
            <b-form-select
                v-model="selectedMinPrice"
                :options="minPriceOptions"
                :class="{ selected: selectedMinPrice }"
                @change="minPriceChanged"
            />
        </b-col>
        <div class="to d-flex align-items-center mb-2 mb-sm-0">
            to
        </div>
        <b-col class="mb-2 mb-sm-0">
            <b-form-select
                v-model="selectedMaxPrice"
                :options="maxPriceOptions"
                :class="{ selected: selectedMaxPrice }"
                @change="maxPriceChanged"
            />
        </b-col>

        <b-col cols="12" sm="3">
            <search-button
                :stock-type="stockType"
                :active="$options.name"
            />
        </b-col>
    </b-row>
</template>

<style lang="scss" scoped>
.to {
    color: #717171;
    font-family: $helvetica-56-italic;
    font-style: italic;
    font-size: px2rem(14);
}
</style>

<script>
import _ from "lodash";
import numeral from "numeral";
import { sync } from "vuex-pathify";
import SearchButton from "./SearchButton";
import {trackGAEvent} from "@/lib/GoogleTagManager";

export default {
    name: "Price",
    components: {SearchButton},
    props: {
        stockType: {
            type: String,
            required: true
        }
    },

    data() {
        return {
            selectedMinPrice: null,
            selectedMaxPrice: null
        };
    },

    mounted() {
        if(this.isMinPriceSelected) {
            this.selectedMinPrice = this.updatedSelectedMinPrice;
        }
        if(this.isMaxPriceSelected) {
            this.selectedMaxPrice = this.updatedSelectedMaxPrice;
        }
    },

    computed: {
        updatedSelectedMinPrice: sync('homeSearch/minPrice'),
        updatedSelectedMaxPrice: sync('homeSearch/maxPrice'),

        isMinPriceSelected() { return !_.isNil(this.updatedSelectedMinPrice); },
        isMaxPriceSelected() { return !_.isNil(this.updatedSelectedMaxPrice); },

        minPriceOptions() {
            const options = [];

            options.push({ value: null, text: "Min: Any" });

            _.range(2000, 10000, 2000).forEach(price => {
                options.push({
                    value: price,
                    text: numeral(price).format("$0,0")
                });
            });

            _.range(10000, 50000, 5000).forEach(price => {
                options.push({
                    value: price,
                    text: numeral(price).format("$0,0")
                });
            });

            _.range(50000, 200000, 25000).forEach(price => {
                options.push({
                    value: price,
                    text: numeral(price).format("$0,0")
                });
            });

            return options;
        },
        maxPriceOptions() {
            const options = [];

            _.range(4000, 10000, 2000).forEach(price => {
                options.push({
                    value: price,
                    text: numeral(price).format("$0,0")
                });
            });

            _.range(10000, 50000, 5000).forEach(price => {
                options.push({
                    value: price,
                    text: numeral(price).format("$0,0")
                });
            });

            _.range(50000, 200000, 25000).forEach(price => {
                options.push({
                    value: price,
                    text: numeral(price).format("$0,0")
                });
            });

            options.push({ value: null, text: "Max: Any" });

            return options;
        }
    },

    methods: {
        minPriceChanged(minPrice) {
            if (minPrice >= this.selectedMaxPrice) {
                this.selectedMaxPrice = null;
                this.updatedSelectedMaxPrice = null;
            }
            this.updatedSelectedMinPrice = minPrice;
        },
        maxPriceChanged(maxPrice) {
            if (maxPrice <= this.selectedMinPrice) {
                this.selectedMinPrice = null;
                this.updatedSelectedMinPrice = null;
            }
            this.updatedSelectedMaxPrice = maxPrice;
        },
        trackGAEventPrice() {
            trackGAEvent(this, {
                category: "home-search",
                action: `search-${this.stockType}-price`,
                label: `${this.selectedMinPrice || "ANY"} - ${this
                    .selectedMaxPrice || "ANY"}`
            });
        }
    }
};
</script>
