import Vue from "vue";
import _ from "lodash";
import "@/lib/polyfills";
import "../../sass/vendor.scss";
import "../../sass/core.scss";
import "../../sass/home.scss";

import TopDeals from "Modules/Home/components/TopDeals";
import TodaysTopDeals from "Modules/Home/components/TodaysTopDeals";
import SavingsFindersLifetimeWarranty from "Modules/Home/components/SavingsFindersLifetimeWarranty";
import LifetimeWarrantyBlock from "Modules/Home/components/LifetimeWarrantyBlock";
import CertifiedDealersBlock from "Modules/Home/components/CertifiedDealersBlock";
import VideoBackground from "Components/VideoBackground";
import VueI18n from "vue-i18n";
import Vuex from "vuex";
import pathify from "@/store/pathify";
import user from "@/store/modules/user";
import headerTransitionStore from "@/store/modules/headerTransitionStore";
import translated from "@/store/modules/translated";
import location from "@/store/modules/location";
import topdeals from "@/store/modules/topdeals";
import campaign from "@/store/modules/campaign";
import sessionReset from "@/store/modules/sessionReset";
import CarSaverPlugin from "@/lib/CarSaverPlugin";
import ABetterWayToBuyCarsBlock from "Modules/Home/components/ABetterWayToBuyCarsBlock";
import StickyFooter from "Modules/Home/components/StickyFooter";
import HomeGeoLocation from "Modules/Home/components/GeoLocation";
import VueGeoLocation from "vue-browser-geolocation";
import Hero from "Modules/Home/components/Hero";
import ShopDotComBlock from "Modules/Home/components/ShopDotComBlock";
import LifetimeWarrantyDisclaimer from "Components/LifetimeWarrantyDisclaimer";
import homeSearch from "@/store/modules/homeSearch";
import SellYourCarBlock from "Modules/Home/components/SellYourCarBlock";

const environment = _.get(window, "appConfig.env[0]", "local") || "local";

Vue.use(Vuex);
Vue.use(VueI18n);
Vue.use(VueGeoLocation);

const debug = environment !== "production";

const store = new Vuex.Store({
    plugins: [pathify.plugin],

    modules: {
        user,
        translated,
        location,
        topdeals,
        sessionReset,
        campaign,
        headerTransitionStore,
        homeSearch
    },
    strict: debug
});

const defaultMessages = _.get(store, "state.translated.text", {});
const messages = {
    default: defaultMessages,
    en: {},
    es: {}
};
const i18n = new VueI18n({
    locale: "default",
    fallbackLocale: "default",
    messages
});

Vue.use(CarSaverPlugin);

if (document.getElementById("root") != null) {
    // eslint-disable-next-line no-new
    new Vue({
        el: "#root",

        store,

        i18n,

        components: {
            TopDeals,
            TodaysTopDeals,
            SavingsFindersLifetimeWarranty,
            LifetimeWarrantyBlock,
            CertifiedDealersBlock,
            ABetterWayToBuyCarsBlock,
            VideoBackground,
            StickyFooter,
            HomeGeoLocation,
            Hero,
            ShopDotComBlock,
            LifetimeWarrantyDisclaimer,
            SellYourCarBlock
        }
    });
}
