import convertible from "../../../../../images/home/convertible_wide.jpg";
import coupe from "../../../../../images/home/coupe_wide.jpg";
import sedan from "../../../../../images/home/sedan_wide.jpg";
import suv from "../../../../../images/home/suv_wide.jpg";
import truck from "../../../../../images/home/truck_wide.jpg";
import van from "../../../../../images/home/van_wide.jpg";
import hatchback from "../../../../../images/home/hatchback_wide.jpg";
import wagon from "../../../../../images/home/wagon_wide.jpg";

export default {
    getUrls: function(bodyStyle) {
        const type =
            typeof bodyStyle === "string" ? bodyStyle.toLowerCase() : "";

        switch (type) {
            case "convertible":
                return {
                    img: convertible,
                    listings: "/shop/models?bodyStyles=convertible&distance=50"
                };
            case "coupe":
                return {
                    img: coupe,
                    listings: "/shop/models?bodyStyles=coupe&distance=50"
                };
            case "hatchback":
                return {
                    img: hatchback,
                    listings: "/shop/models?bodyStyles=hatchback&distance=50"
                };
            case "sedan":
                return {
                    img: sedan,
                    listings: "/shop/models?bodyStyles=sedan&distance=50"
                };
            case "suv":
                return {
                    img: suv,
                    listings: "/shop/models?bodyStyles=suv&distance=50"
                };
            case "truck":
                return {
                    img: truck,
                    listings: "/shop/models?bodyStyles=truck&distance=50"
                };
            case "van":
                return {
                    img: van,
                    listings: "/shop/models?bodyStyles=van&distance=50"
                };
            case "wagon":
                return {
                    img: wagon,
                    listings: "/shop/models?bodyStyles=wagon&distance=50"
                };
            default:
                return {
                    img: "",
                    listings: ""
                };
        }
    }
};
