var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "aBetterWayToBuyCars" } },
    [
      _c(
        "b-container",
        { staticClass: "p-sm-0 position-relative", attrs: { fluid: "" } },
        [
          _c("b-row", { staticClass: "content-card" }, [
            _c(
              "div",
              { staticClass: "col-12 col-md-6 left-block order-2 order-md-1" },
              [
                _c("div", { staticClass: "d-flex flex-column text-block" }, [
                  _c("h1", { staticClass: "d-none d-md-flex" }, [
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm.$t("component.aBetterWayToBuyCars.header")) +
                        "\n                    "
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "d-flex flex-column mb-3" }, [
                    _c("div", { staticClass: "d-flex step-block" }, [
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.$t("component.aBetterWayToBuyCars.step1"))
                        )
                      ]),
                      _vm._v(" "),
                      _c("span", [_vm._v(" | ")]),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.$t("component.aBetterWayToBuyCars.pick"))
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "sub-text" }, [
                      _vm._v(
                        "\n                            " +
                          _vm._s(
                            _vm.$t("component.aBetterWayToBuyCars.dreamCar")
                          ) +
                          "\n                        "
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "d-flex flex-column mb-3" }, [
                    _c("div", { staticClass: "d-flex step-block" }, [
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.$t("component.aBetterWayToBuyCars.step2"))
                        )
                      ]),
                      _vm._v(" "),
                      _c("span", [_vm._v(" | ")]),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.$t("component.aBetterWayToBuyCars.price"))
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "sub-text" }, [
                      _vm._v(
                        "\n                            " +
                          _vm._s(
                            _vm.$t("component.aBetterWayToBuyCars.lowPrices")
                          ) +
                          "\n                        "
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "d-flex flex-column mb-3" }, [
                    _c("div", { staticClass: "d-flex step-block" }, [
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.$t("component.aBetterWayToBuyCars.step3"))
                        )
                      ]),
                      _vm._v(" "),
                      _c("span", [_vm._v(" | ")]),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.$t("component.aBetterWayToBuyCars.drive"))
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "sub-text" }, [
                      _vm._v(
                        "\n                            " +
                          _vm._s(
                            _vm.$t("component.aBetterWayToBuyCars.worryFree")
                          ) +
                          "\n                        "
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex flex-column justify-content-center justify-content-sm-start align-items-center flex-sm-row w-100"
                    },
                    [
                      _c(
                        "learn-more-button",
                        {
                          staticClass: "mr-0 mr-sm-2 my-2 my-sm-0",
                          on: { click: _vm.goToShopNew }
                        },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(
                                _vm.$t("component.aBetterWayToBuyCars.shopNew")
                              ) +
                              "\n                        "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "learn-more-button",
                        {
                          staticClass: "ml-0 ml-sm-2 my-2 my-sm-0",
                          on: { click: _vm.goToShopUsed }
                        },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(
                                _vm.$t("component.aBetterWayToBuyCars.shopUsed")
                              ) +
                              "\n                        "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ])
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "col-12 col-md-6 d-flex flex-column justify-content-center align-items-center right-block order-1 order-md-2"
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex d-md-none flex-column text-block mb-3 w-100"
                  },
                  [
                    _c("h1", { staticClass: "align-self-start mb-0" }, [
                      _vm._v(
                        "\n                        " +
                          _vm._s(
                            _vm.$t("component.aBetterWayToBuyCars.header")
                          ) +
                          "\n                    "
                      )
                    ]),
                    _vm._v(" "),
                    _c("h2", { staticClass: "align-self-start" }, [
                      _vm._v(
                        "\n                        " +
                          _vm._s(
                            _vm.$t("component.aBetterWayToBuyCars.subHeader")
                          ) +
                          "\n                    "
                      )
                    ])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "phone-image" },
                  [
                    _c("b-img-lazy", {
                      directives: [{ name: "webp", rawName: "v-webp" }],
                      attrs: {
                        src: require("./images/iphone_w_car.png"),
                        fluid: "",
                        alt: "a_better_way_to_buy_cars_phone_and_car_image"
                      }
                    })
                  ],
                  1
                )
              ]
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }