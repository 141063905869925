var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-button",
    {
      staticClass: "make-model-search-btn mb-2 mb-sm-0",
      attrs: { variant: "orange", disabled: _vm.loading, block: "" },
      on: { click: _vm.search }
    },
    [_vm._v("\n    Search\n")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }