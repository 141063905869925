<template>
    <b-button size="xlg" variant="outline-primary" @click="click">
        <slot />
    </b-button>
</template>

<style lang="scss" scoped>
.btn-outline-primary {
    height: 45px;
    width: 100%;
    max-width: 275px;
    font-size: px2rem(16);
    font-family: $helvetica-55-Roman;
    display: block;

    @include media-breakpoint-up(sm) {
        max-width: 195px !important;
    }
}
</style>

<script>
export default {
    name: "LearnMoreButton",

    methods: {
        click() {
            this.$emit("click");
        }
    }
};
</script>
