<template>
    <div id="aBetterWayToBuyCars">
        <b-container fluid class="p-sm-0 position-relative">
            <b-row class="content-card">
                <div class="col-12 col-md-6 left-block order-2 order-md-1">
                    <div class="d-flex flex-column text-block">
                        <h1 class="d-none d-md-flex">
                            {{ $t("component.aBetterWayToBuyCars.header") }}
                        </h1>
                        <div class="d-flex flex-column mb-3">
                            <div class="d-flex step-block">
                                <span>{{
                                    $t("component.aBetterWayToBuyCars.step1")
                                }}</span>
                                <span> | </span>
                                <span>{{
                                    $t("component.aBetterWayToBuyCars.pick")
                                }}</span>
                            </div>
                            <span class="sub-text">
                                {{
                                    $t("component.aBetterWayToBuyCars.dreamCar")
                                }}
                            </span>
                        </div>
                        <div class="d-flex flex-column mb-3">
                            <div class="d-flex step-block">
                                <span>{{
                                    $t("component.aBetterWayToBuyCars.step2")
                                }}</span>
                                <span> | </span>
                                <span>{{
                                    $t("component.aBetterWayToBuyCars.price")
                                }}</span>
                            </div>
                            <span class="sub-text">
                                {{
                                    $t(
                                        "component.aBetterWayToBuyCars.lowPrices"
                                    )
                                }}
                            </span>
                        </div>
                        <div class="d-flex flex-column mb-3">
                            <div class="d-flex step-block">
                                <span>{{
                                    $t("component.aBetterWayToBuyCars.step3")
                                }}</span>
                                <span> | </span>
                                <span>{{
                                    $t("component.aBetterWayToBuyCars.drive")
                                }}</span>
                            </div>
                            <span class="sub-text">
                                {{
                                    $t(
                                        "component.aBetterWayToBuyCars.worryFree"
                                    )
                                }}
                            </span>
                        </div>
                        <div
                            class="d-flex flex-column justify-content-center justify-content-sm-start align-items-center flex-sm-row w-100"
                        >
                            <learn-more-button
                                class="mr-0 mr-sm-2 my-2 my-sm-0"
                                @click="goToShopNew"
                            >
                                {{
                                    $t("component.aBetterWayToBuyCars.shopNew")
                                }}
                            </learn-more-button>
                            <learn-more-button
                                class="ml-0 ml-sm-2 my-2 my-sm-0"
                                @click="goToShopUsed"
                            >
                                {{
                                    $t("component.aBetterWayToBuyCars.shopUsed")
                                }}
                            </learn-more-button>
                        </div>
                    </div>
                </div>
                <div
                    class="col-12 col-md-6 d-flex flex-column justify-content-center align-items-center right-block order-1 order-md-2"
                >
                    <div
                        class="d-flex d-md-none flex-column text-block mb-3 w-100"
                    >
                        <h1 class="align-self-start mb-0">
                            {{ $t("component.aBetterWayToBuyCars.header") }}
                        </h1>
                        <h2 class="align-self-start">
                            {{ $t("component.aBetterWayToBuyCars.subHeader") }}
                        </h2>
                    </div>
                    <div class="phone-image">
                        <b-img-lazy
                            v-webp
                            src="./images/iphone_w_car.png"
                            fluid
                            alt="a_better_way_to_buy_cars_phone_and_car_image"
                        />
                    </div>
                </div>
            </b-row>
        </b-container>
    </div>
</template>
<script>
import LearnMoreButton from "Modules/Home/components/LearnMoreButton";

export default {
    name: "ABetterWayToBuyCarsBlock",
    components: { LearnMoreButton },
    methods: {
        goToShopNew() {
            document.location = "/shop-by";
        },
        goToShopUsed() {
            document.location = "/shop/used";
        }
    }
};
</script>
<style lang="scss">
#aBetterWayToBuyCars {
    padding: 5px 0;
    background-color: #f9f9f9;

    @include media-breakpoint-up(sm) {
        padding: 17.5px 0;
        background-color: $white;

        .phone-image {
            padding: 0 55px 0 0;
        }
    }

    .container-fluid {
        background-color: #f9f9f9;

        .content-card {
            background-color: $white;
            width: 100%;
            height: auto;
            min-height: 455px;
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
            border-radius: 2px;
            padding: 20px;
            margin-right: 0;
            margin-left: 0;
        }
    }

    .left-block {
        z-index: 1;
    }

    .right-block {
        z-index: 0;
        padding: 0;
    }

    .text-block {
        h1 {
            color: #444444;
            font-weight: 300;
            font-size: px2rem(26);
            line-height: 35px;
            width: 100%;
        }

        h2 {
            font-size: px2rem(16);
        }

        .step-block {
            font-family: $helvetica-55-Roman;
            font-size: px2em(18);
            color: #444444;

            span {
                margin-right: 5px;
            }
        }

        .sub-text {
            max-width: 440px;
            color: #444444;
            font-size: px2em(16);
            line-height: 20px;
        }
    }

    .phone-image {
        img {
            max-height: 450px;
            max-width: 100%;
            margin: auto;
            display: block;
        }
    }

    @include media-breakpoint-down(md) {
        height: 100%;

        .text-block {
            .step-block {
                font-size: 16px;
                line-height: 25px;
            }

            .sub-text {
                font-size: 14px;
                line-height: 20px;
            }
        }
        .phone-image {
            img {
                max-width: calc(100% - 50px);
            }
        }
    }

    @include media-breakpoint-up(md) {
        .content-card {
            background: url("./images/gray_circle.png") no-repeat 50%;
            background-size: cover;
            .text-block {
                padding: 60px 0 25px 0;

                h1 {
                    width: 507px;
                    font-size: px2em(45);
                    line-height: 50px;
                    margin-bottom: 35px;
                    max-width: 100%;
                    font-weight: 300;
                }
            }
        }
    }
}
</style>
