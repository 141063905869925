<template>
    <div id="lifetime-warranty-block">
        <b-container fluid class="p-sm-0">
            <b-row class="content-card">
                <b-col
                    cols="12"
                    sm="4"
                    class="d-none d-sm-flex justify-content-center align-items-center"
                >
                    <b-img-lazy
                        v-webp
                        class="car-image"
                        src="./images/car.png"
                        fluid
                        alt="Lifetime Warranty Car"
                    />
                </b-col>
                <b-col
                    cols="12"
                    sm="8"
                    class="p-0 d-flex flex-column justify-content-center"
                >
                    <h2 :style="[isSpanish ? { fontSize: '48px' } : {}]">
                        {{ $t("component.lifetimeWarrantyBlock.title") }}
                    </h2>

                    <h3 class="mb-3">
                        {{ $t("component.lifetimeWarrantyBlock.subTitle") }}
                    </h3>

                    <div class="d-flex mb-2 mb-sm-0">
                        <div class="d-sm-none d-block mr-4">
                            <b-img-lazy
                                v-webp
                                class="car-image"
                                src="./images/car.png"
                                fluid
                                alt="Lifetime Warranty Car"
                            />
                        </div>
                        <div>
                            <div class="mb-2">
                                <span>{{
                                    $t(
                                        "component.lifetimeWarrantyBlock.benefits"
                                    )
                                }}</span>
                            </div>
                            <ul>
                                <li>
                                    {{
                                        $t(
                                            "component.lifetimeWarrantyBlock.item1"
                                        )
                                    }}
                                </li>
                                <li>
                                    {{
                                        $t(
                                            "component.lifetimeWarrantyBlock.item2"
                                        )
                                    }}
                                </li>
                                <li>
                                    {{
                                        $t(
                                            "component.lifetimeWarrantyBlock.item3"
                                        )
                                    }}
                                </li>
                            </ul>
                        </div>
                    </div>

                    <learn-more-button @click="gotoWarrantyPage">
                        {{ $t("component.lifetimeWarrantyBlock.learnMore") }}
                    </learn-more-button>
                </b-col>
                <div class="col-12 mt-3">
                    <lifetime-warranty-disclaimer class="copy" />
                </div>
            </b-row>
        </b-container>
    </div>
</template>

<script>
import { get } from "vuex-pathify";
import LearnMoreButton from "Modules/Home/components/LearnMoreButton";
import LifetimeWarrantyDisclaimer from "Components/LifetimeWarrantyDisclaimer";

export default {
    name: "LifetimeWarrantyBlock",

    components: { LearnMoreButton, LifetimeWarrantyDisclaimer },

    computed: {
        lang: get("translated/lang"),
        isSpanish() {
            return this.lang === "es";
        }
    },

    methods: {
        gotoWarrantyPage() {
            document.location = "/warranty";
        }
    }
};
</script>

<style lang="scss" scoped>
.webp {
    #lifetime-warranty-block {
        > .container-fluid {
            .content-card {
                background: url("./images/lifetime_block_background.webp")
                no-repeat 50%;
            }
        }
    }
}

.no-webp {
    #lifetime-warranty-block {
        > .container-fluid {
            .content-card {
                background: url("./images/lifetime_block_background.jpg")
                no-repeat 50%;
            }
        }
    }
}

#lifetime-warranty-block {
    padding: 5px 0;
    background-color: #f9f9f9;

    @include media-breakpoint-up(sm) {
        padding: 17.5px 0;
        background-color: $white;
    }

    > .container-fluid {
        background-color: #f9f9f9;

        .content-card {
            background-size: cover;
            width: 100%;
            height: auto;
            min-height: 455px;
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
            border-radius: 2px;
            padding: 20px 15px;
            margin-right: 0;
            margin-left: 0;
        }

        h2 {
            color: $dark-gray;
            font-size: 45px;
            font-weight: 300;
        }

        h3 {
            font-size: 16px;
            color: $faq-answer-gray;
            line-height: 20px;
        }

        li {
            color: $faq-answer-gray;
            font-size: 16px;
            line-height: 25px;
        }

        ul {
            padding-left: 20px;
        }

        span {
            color: $dark-gray;
            font-size: 22px;
            line-height: 25px;
            font-family: $helvetica-55-Roman;
        }

        .lifetime-warranty-logo {
            position: absolute;
            bottom: 0;
            right: 0;
            max-height: 70px;
            width: 170px;
        }

        .car-image {
            max-height: 285px;
        }

        .copy {
            width: 100%;
            max-width: 1140px;
            font-size: px2rem(12);
            font-style: italic;
            line-height: px2rem(13);
            color: $muted-dark-gray;
            text-decoration: none;

            a {
                color: $muted-dark-gray;
            }
        }
    }

    @include media-breakpoint-down(sm) {
        > .container-fluid {
            .content-card {
                min-height: 85px;
            }

            h2 {
                font-size: 25px !important;
                margin-bottom: 0;
            }

            h3 {
                font-size: px2rem(16);
            }

            span {
                margin: 0 0 10px;
                font-size: 14px;
                line-height: 20px;
                font-weight: normal;
            }

            li {
                font-size: 14px;
                line-height: 20px;
            }

            button {
                margin: 0 auto;
            }

            .lifetime-warranty-logo {
                position: relative;
                margin: 20px auto 0;
            }
        }
    }
}
</style>
