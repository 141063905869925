<template>
    <b-container fluid class="p-sm-0">
        <b-row class="content-card">
            <slot />
        </b-row>
    </b-container>
</template>
<script>
export default {
    name: "ContentCard"
};
</script>
<style lang="scss" scoped>
.container-fluid {
    background-color: $very-light-gray;
    padding-top: 5px;
    padding-bottom: 5px;

    .content-card {
        background: $white;
        width: 100%;
        height: auto;
        min-height: 85px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
        border-radius: 2px;
        padding: 20px;
        margin-right: 0;
        margin-left: 0;
    }
}

@include media-breakpoint-up(sm) {
    .container-fluid {
        background-color: transparent;
        padding-top: 0;
        padding-bottom: 0;

        .content-card {
            background: $very-light-gray;
            box-shadow: none;
            border-radius: 0;
        }
    }
}
</style>
