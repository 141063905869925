<template>
    <div id="certified-dealers-block">
        <b-container fluid class="p-sm-0">
            <b-row class="content-card">
                <div
                    class="p-0 p-sm-3 col-md-6 offset-md-6 d-flex flex-column justify-content-end"
                >
                    <h2>{{ $t("component.certifiedDealers.header") }}</h2>

                    <h3 class="mb-4">
                        {{ $t("component.certifiedDealers.subHeader") }}
                    </h3>

                    <ul>
                        <li>{{ $t("component.certifiedDealers.item1") }}</li>
                        <li>{{ $t("component.certifiedDealers.item2") }}</li>
                        <li>{{ $t("component.certifiedDealers.item3") }}</li>
                        <li>{{ $t("component.certifiedDealers.item4") }}</li>
                        <li>{{ $t("component.certifiedDealers.item5") }}</li>
                        <li>{{ $t("component.certifiedDealers.item6") }}</li>
                    </ul>

                    <div
                        class="d-flex d-flex justify-content-center justify-content-sm-start"
                    >
                        <learn-more-button @click="gotoDealerOverviewPage">
                            {{ $t("component.certifiedDealers.learnMore") }}
                        </learn-more-button>
                    </div>
                </div>
            </b-row>
        </b-container>
    </div>
</template>

<style lang="scss">
.no-webp {
    #certified-dealers-block {
        > .container-fluid {
            .content-card {
                background: url("./images/certified_dealer_bg.png") no-repeat;
            }
        }
    }
}

.webp {
    #certified-dealers-block {
        > .container-fluid {
            .content-card {
                background: url("./images/certified_dealer_bg.webp") no-repeat;
            }
        }
    }
}

#certified-dealers-block {
    padding: 5px 0;

    @include media-breakpoint-up(sm) {
        padding: 17.5px 0;
        background-color: $white;
    }

    > .container-fluid {
        background-color: #fff;

        .content-card {
            background-size: cover;
            width: 100%;
            height: auto;
            min-height: 400px;
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
            border-radius: 2px;
            padding: 20px;
            margin-right: 0;
            margin-left: 0;
        }

        h2 {
            color: $dark-gray;
            font-size: 45px;
        }
        h3 {
            font-size: 16px;
            line-height: 20px;
        }

        li {
            color: $faq-answer-gray;
            font-size: 14px;
            line-height: 15px;
            margin-bottom: 10px;
        }

        ul {
            padding-left: 20px;
            margin-bottom: 20px;
        }
    }

    @include media-breakpoint-down(md) {
        > .container-fluid {
            h2 {
                font-size: 26px;
                line-height: 35px;
                margin-bottom: 0;
            }

            li {
                color: $dark-gray;
                font-size: 14px;
                line-height: 20px;
            }
        }
    }
}
</style>

<script>
import LearnMoreButton from "Modules/Home/components/LearnMoreButton";

export default {
    name: "CertifiedDealersBlock",
    components: { LearnMoreButton },

    methods: {
        gotoDealerOverviewPage() {
            document.location = "/dealer-overview";
        }
    }
};
</script>
