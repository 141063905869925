var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "d-flex justify-content-center" }, [
    _c(
      "div",
      {
        staticClass:
          "walmart-cta-block w-100 text-center d-flex flex-column align-items-center justify-content-center p-3"
      },
      [
        _c("h1", { staticClass: "title mx-5" }, [
          _vm._v(
            "\n            Everyday Low Prices on New & Used Cars\n        "
          )
        ]),
        _vm._v(" "),
        _c("h2", [_vm._v("Lifetime Warranty Standard*")]),
        _vm._v(" "),
        _c(
          "b-tabs",
          {
            staticClass: "search-wrapper w-100",
            attrs: { "no-fade": "", "no-nav-style": "", fill: "" }
          },
          [
            _c("b-tab", { attrs: { title: "New" } }, [_c("new-search")], 1),
            _vm._v(" "),
            _c("b-tab", { attrs: { title: "Used" } }, [_c("used-search")], 1)
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }