<template>
    <div class="video-button">
        <span
            class="wistia_embed wistia_async_ylo4zgokrw popover=true popoverContent=link"
            style="display:inline;position:relative"
        >
            <i aria-hidden="true" class="ion-ios-play" />
        </span>
    </div>
</template>

<script>
export default {
    name: "VideoButton",

    mounted() {
        this.initWistia();
        this.initVideo();
    },

    methods: {
        initWistia() {
            let wistiaScript = null;

            if (window.Wisita) {
                return;
            }

            wistiaScript = document.createElement("script");
            wistiaScript.setAttribute(
                "src",
                "https://fast.wistia.com/assets/external/E-v1.js"
            );

            document.head.appendChild(wistiaScript);
        },

        initVideo() {
            const videoScript = document.createElement("script");

            videoScript.setAttribute(
                "src",
                "https://fast.wistia.com/embed/medias/ylo4zgokrw.jsonp"
            );
            document.head.appendChild(videoScript);
        }
    }
};
</script>

<style lang="scss" scoped>
.video-button {
    .ion-ios-play {
        font-size: 100px;
        color: white;
        opacity: 0.8;

        &:hover {
            cursor: pointer;
            opacity: 1;
        }

        @include media-breakpoint-down(sm) {
            font-size: 75px;
        }
    }
}
</style>
