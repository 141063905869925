<template>
    <div v-if="deals.length > 0" class="top-deals container-fluid p-0">
        <div class="header d-flex justify-content-center px-3">
            <h1 class="title m-0">
                {{ $t("component.topDeals.header.part1") }}
                {{ $t("component.topDeals.header.part2") }}
            </h1>
        </div>
        <div v-for="deal in deals" :key="deal.bodyStyle" class="deal">
            <deal-card :deal="deal" />
        </div>
    </div>
</template>
<script>
import { get, dispatch } from "vuex-pathify";
import DealCard from "./components/DealCard";
import _ from "lodash";

export default {
    name: "TopDeals",

    components: {
        DealCard
    },

    computed: {
        location: get("location/selectedLocation"),
        deals: get("topdeals/bodyStyleDeals@deals")
    },
    watch: {
        location(value) {
            const zipCode = _.get(value, "zipCode", null);
            if (!_.isNil(zipCode)) {
                dispatch("topdeals/loadDeals", value.zipCode);
            }
        }
    },

    created() {
        const zipCode = _.get(this.location, "zipCode", null);
        if (!_.isNil(zipCode)) {
            dispatch("topdeals/loadDeals", zipCode);
        }
    }
};
</script>
<style lang="scss" scoped>
.top-deals {
    max-width: 1140px;
    background-color: #f9f9f9;

    .header {
        line-height: 33px;
        color: #444444;
        font-weight: 300;
        width: 100%;
        padding: 25px 0;

        .title {
            font-size: px2rem(26);
            font-weight: 300;
        }
    }

    .deal {
        padding: 5px 15px 5px 15px;
    }

    @include media-breakpoint-up(sm) {
        background-color: transparent;

        .header {
            line-height: 55px;
            padding: 35px 0 25px 0;

            .title {
                font-size: px2rem(45);
            }
        }

        .deal {
            padding: 10px 0 10px 0;
        }
    }
}
</style>
