<template>
    <b-button
        variant="orange"
        class="make-model-search-btn mb-2 mb-sm-0"
        :disabled="loading"
        block
        @click="search"
    >
        Search
    </b-button>
</template>

<script>
import _ from "lodash";
import { get } from "vuex-pathify";

export default {
    name: "SearchButton",
    props: {
        loading: {
            type: Boolean,
            required: false
        },
        stockType: {
            type: String,
            required: true
        },
        active: {
            type: String,
            required: true
        }
    },
    computed: {
        make: get("homeSearch/selectedMake"),
        model: get("homeSearch/selectedModel"),
        bodyStyle: get("homeSearch/selectedBodyStyle"),
        bodyStyles: get("homeSearch/selectedBodyStyles"),
        minPrice: get("homeSearch/minPrice"),
        maxPrice: get("homeSearch/maxPrice"),
        paymentType: get("homeSearch/paymentType"),
        minPayment: get("homeSearch/minPayment"),
        maxPayment: get("homeSearch/maxPayment")
    },
    methods: {
        search() {
            this.trackEvents();

            let url = "";
            if (this.stockType === "NEW") {
                if (this.isOnlyMakeModel()) {
                    url = "/shop-by/make";
                    if (this.make) {
                        url += `/${this.make.name}`;

                        if (this.model) {
                            url += `/model/${this.model.id}`;

                            if (this.bodyStyle) {
                                url += `/${this.bodyStyle}`;
                            }
                        }
                    }
                    document.location = url;
                } else {
                    url = "/shop/models?stockTypes=NEW";
                    this.buildUrlQuery(url);
                }
            } else {
                url = "/shop/used?stockTypes=USED";
                this.buildUrlQuery(url);
            }
        },
        isOnlyMakeModel() {
            return (
                this.bodyStyles.length === 0 &&
                _.isNil(this.minPrice) &&
                _.isNil(this.maxPrice) &&
                _.isNil(this.minPayment) &&
                _.isNil(this.maxPayment) &&
                _.isNil(this.paymentType)
            );
        },
        buildUrlQuery(url) {
            if (!_.isNil(this.make)) {
                url += `&makes=${this.make.name}`;
                //this is nested to prevent any instance where model/bodystyle is selected but not make
                if (!_.isNil(this.model)) {
                    url += `&models=${this.model.name}`;
                }
                if (!_.isNil(this.bodyStyle)) {
                    url += `&bodyStyle=${this.bodyStyle}`;
                }
            }
            if (this.bodyStyles.length > 0) {
                url += `&bodyStyles=${_.join(this.bodyStyles, ",")}`;
            }
            if (!_.isNil(this.minPrice)) {
                url += `&minPrice=${this.minPrice}`;
            }
            if (!_.isNil(this.maxPrice)) {
                url += `&maxPrice=${this.maxPrice}`;
            }
            if (!_.isNil(this.paymentType)) {
                url += `&paymentType=${this.paymentType}`;
            }
            if (!_.isNil(this.minPayment)) {
                url += `&minPayment=${this.minPayment}`;
            }
            if (!_.isNil(this.maxPayment)) {
                url += `&maxPayment=${this.maxPayment}`;
            }

            document.location = url;
        },
        trackEvents() {
            //this calls a specific tracking method that lives in the parent component
            //the reason the tracking events aren't in this component is to remove
            //any circular references
            switch (this.active) {
                case "MakeModel":
                    this.$parent.trackGAEventMakeModel();
                    break;
                case "BodyStyle":
                    this.$parent.trackGAEventBodyStyle();
                    break;
                case "Price":
                    this.$parent.trackGAEventPrice();
                    break;
                case "Payment":
                    this.$parent.trackGAEventPayment();
                    break;
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.make-model-search-btn {
    height: 47px;

    @include media-breakpoint-up(sm) {
        max-width: 250px;
        height: unset;
    }
}
</style>
