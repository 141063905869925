<template>
    <div class="shop-hero-block">
        <div
            class="shop-hero hero-full-height bg-img d-flex align-items-end justify-content-between"
        >
            <div class="text-wrapper d-none d-md-block">
                <h1 class="title">
                    All New Auto-Buying Program
                </h1>
                <hr />
                <svg-fallback class="shop-logo" :src="logoImgSvg">
                    <img alt="Shop.com" :src="logoImgPng" />
                </svg-fallback>
            </div>

            <div class="logo-wrapper d-none d-md-block">
                <img
                    src="./images/cs_logo_white.png"
                    class="logo-img"
                    alt="CarSaver"
                />
            </div>
        </div>
        <div class="shop-cta-block d-flex align-items-center">
            <div
                class="text-wrapper d-flex d-sm-none align-items-center justify-content-center"
            >
                <h1 class="title">
                    All New <br />
                    Auto-Buying Program
                </h1>

                <svg-fallback class="shop-logo" :src="logoImgSvg">
                    <img alt="Shop.com" :src="logoImgPng" />
                </svg-fallback>

                <div
                    class="button-wrapper d-flex flex-column justify-content-center align-items-center w-100"
                >
                    <a
                        href="/shop-by/payment?paymentType=LEASE"
                        class="btn btn-primary btn-xlg"
                        >Shop Leases</a
                    >
                    <a href="/shop-by" class="btn btn-very-dark-blue btn-xlg">
                        {{ $t("page.home.floatingFooter.new") }}
                    </a>
                    <a href="/shop/used" class="btn btn-dark-blue btn-xlg">
                        {{ $t("page.home.floatingFooter.used") }}
                    </a>
                </div>
            </div>

            <div
                class="button-wrapper d-none d-sm-flex justify-content-center align-items-center w-100"
            >
                <a
                    href="/shop-by/payment?paymentType=LEASE"
                    class="btn btn-primary btn-xlg mr-3"
                    >Shop Leases</a
                >
                <a href="/shop-by" class="btn btn-very-dark-blue btn-xlg mr-3">
                    {{ $t("page.home.floatingFooter.new") }}
                </a>
                <a href="/shop/used" class="btn btn-dark-blue btn-xlg">
                    {{ $t("page.home.floatingFooter.used") }}
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import SvgFallback from "Components/SvgFallback";
import logoImgPng from "./images/shop-logo.png";
import logoImgSvg from "./images/shop-logo.svg";

export default {
    name: "ShopHero",
    components: { SvgFallback },
    data() {
        return {
            logoImgPng,
            logoImgSvg
        };
    }
};
</script>

<style lang="scss">
.webp {
    .shop-hero-block {
        .shop-hero.bg-img {
            background: url("./images/hero-lg.webp") no-repeat center center;

            @include media-breakpoint-down(md) {
                background: url("./images/hero-md.webp") no-repeat center center;
            }

            @include media-breakpoint-down(xs) {
                background: url("./images/hero-sm.webp") no-repeat center center;
            }
        }
    }
}

.no-webp {
    .shop-hero-block {
        .shop-hero.bg-img {
            background: url("./images/hero-lg.jpg") no-repeat center center;

            @include media-breakpoint-down(md) {
                background: url("./images/hero-md.jpg") no-repeat center center;
            }

            @include media-breakpoint-down(xs) {
                background: url("./images/hero-sm.jpg") no-repeat center center;
            }
        }
    }
}
</style>

<style lang="scss">
.shop-hero-block {
    .shop-hero {
        height: 440px;
        overflow: hidden;
        background-size: cover !important;
        padding-bottom: 50px;

        @include media-breakpoint-down(sm) {
            height: 210px !important;
        }

        .text-wrapper {
            height: 150px;
            width: 415px;
            border-radius: 0 4px 4px 0;
            background-color: rgba(4, 168, 201, 0.85);
            color: $white;
            padding: 12px 36px;

            .title {
                font-family: $helvetica-55-Roman;
                font-size: 24px;
                letter-spacing: 0.7px;
                line-height: 50px;
                margin-bottom: 0px;
            }

            .shop-logo {
                img,
                svg {
                    width: 100%;
                }
            }

            hr {
                color: $white;
                border-color: $white;
                border-style: solid;
                margin: 0px 0px 8px 0px;
            }

            h2 {
                font-family: $helvetica-46-light-italic;
                padding-top: 5px;
            }
        }

        .logo-wrapper {
            margin-right: 22px;

            .logo-img {
                height: auto;
                width: 238px;
            }
        }
    }

    .shop-cta-block {
        height: 80px;
        width: 100%;
        background-color: $very-light-gray;
        box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.22);
        flex-flow: row nowrap;
        padding-right: 15px;
        padding-left: 15px;

        .text-wrapper {
            flex-direction: column;
            color: white;
            text-align: center;
            border-radius: 4px;
            background-color: rgba(4, 168, 201, 1);
            margin: -40px 15px 0;
            padding: 20px 15px 25px 15px;
            margin-right: 20px;
            margin-left: 20px;
            width: 100%;

            h1 {
                font-size: px2em(28);
                line-height: 35px;
                border-bottom: 1px solid #ddd;
                padding-bottom: 15px;

                @include media-breakpoint-down(md) {
                    font-size: px2em(24);
                }
            }

            h2 {
                color: $muted-dark-gray;
                font-style: italic;
                font-size: px2em(22);
                line-height: 35px;
                text-align: center;
            }
        }

        .button-wrapper {
            .btn-primary,
            .btn-dark-blue,
            .btn-very-dark-blue {
                width: 225px;
                max-width: 275px;
                font-family: $helvetica-55-Roman;
            }
        }
    }

    @include media-breakpoint-up(xl) {
        .shop-hero {
            background-position-y: -220px;
        }
    }

    @include media-breakpoint-down(md) {
        .hero-full-height {
        }
    }

    @include media-breakpoint-down(sm) {
        .cs-buttons {
            bottom: 160px !important;
        }

        .cs-anchor {
            h2 {
                font-size: px2em(16) !important;
            }
        }

        .shop-cta-block {
            background-color: $white;
            box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.22);

            .button-wrapper {
                .btn-primary,
                .btn-dark-blue,
                .btn-very-dark-blue {
                    max-width: 170px;
                }
            }
        }
    }

    @include media-breakpoint-down(xs) {
        .shop-cta-block {
            flex-direction: column;
            height: auto;
            padding-bottom: 5px;
            background: #f9f9f9;

            .button-wrapper {
                .btn-primary,
                .btn-dark-blue,
                .btn-very-dark-blue {
                    width: 100%;
                    max-width: 275px;
                    margin-bottom: 10px;
                }
            }
        }
    }
}
</style>
