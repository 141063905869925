var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "hero" },
    [
      _vm.enabledHeroComponent === "carsaverAtWalmartHero"
        ? _c("carsaver-at-walmart-hero")
        : _vm._e(),
      _vm._v(" "),
      _vm.enabledHeroComponent === "shopHero" ? _c("shop-hero") : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }