<template>
    <div id="savingsFinderLifetimeWarranty">
        <content-card>
            <section
                class="col-12 d-flex flex-column flex-md-row justify-content-md-between align-items-md-center w-100 px-sm-3 px-md-0"
            >
                <div class="d-flex flex-column">
                    <h1 class="title">
                        {{
                            $t(
                                "component.savingsFindersLifetimeWarranty.savingsFinder"
                            )
                        }}
                    </h1>
                    <p class="sub-text" style="max-width: 290px;">
                        {{
                            $t(
                                "component.savingsFindersLifetimeWarranty.seeHow"
                            )
                        }}
                    </p>
                </div>
                <div class="d-flex justify-content-center align-items-center">
                    <b-link
                        class="btn btn-outline-primary btn-xlg"
                        href="/pick-your-flow"
                    >
                        {{ $t("component.savingsFindersLifetimeWarranty.go") }}
                    </b-link>
                </div>
            </section>

            <div class="d-none d-md-flex divider" />

            <section
                class="col-12 d-none d-md-flex flex-row justify-content-between align-items-center p-0"
            >
                <div class="d-flex flex-column">
                    <h1 class="title">
                        {{
                            $t(
                                "component.savingsFindersLifetimeWarranty.lifeTimeWarranty"
                            )
                        }}
                    </h1>
                    <p class="sub-text">
                        {{
                            $t("component.savingsFindersLifetimeWarranty.title")
                        }}
                    </p>
                </div>
                <div class="d-flex justify-content-center align-items-center">
                    <a
                        href="/warranty"
                        class="btn btn-outline-primary btn-xlg poop"
                    >
                        {{
                            $t(
                                "component.savingsFindersLifetimeWarranty.learnMore"
                            )
                        }}
                    </a>
                </div>
            </section>
        </content-card>

        <!--Hides on desktop-->
        <content-card class="d-flex d-md-none">
            <section class="col-12 d-flex flex-column w-100 px-sm-3">
                <div class="d-flex flex-column">
                    <h1 class="title">
                        Lifetime Warranty
                    </h1>
                    <p class="sub-text">
                        {{
                            $t("component.savingsFindersLifetimeWarranty.title")
                        }}
                    </p>
                </div>

                <div class="d-flex justify-content-center align-items-center">
                    <a href="/warranty" class="btn btn-outline-primary btn-xlg">
                        {{
                            $t(
                                "component.savingsFindersLifetimeWarranty.learnMore"
                            )
                        }}
                    </a>
                </div>
            </section>
        </content-card>
    </div>
</template>
<style lang="scss">
#savingsFinderLifetimeWarranty {
    margin: 0;

    .content-card {
        align-items: center;
        justify-content: space-around;

        section {
            max-width: 100%;

            .title {
                font-weight: 300;
                font-size: 26px;
            }

            .sub-text {
                font-size: px2rem(16);
                line-height: 20px;
                color: $dark-gray;
                max-width: 340px;
            }

            a,
            button {
                width: 275px;
                font-size: px2rem(16);
                min-width: 135px;
                font-family: $helvetica-55-Roman;
            }

            button {
                display: block;
            }
        }

        .divider {
            height: 1px;
            width: 70%;
            margin: 15px;
            background-color: rgba(136, 136, 136, 0.75);
            box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
        }
    }

    @include media-breakpoint-up(md) {
        margin: 17.5px 0;

        .content-card {
            align-items: center;
            justify-content: space-around;

            section {
                max-width: 500px;

                .title {
                    font-size: px2rem(45);
                    margin-bottom: 2px;
                }
                .sub-text {
                    margin-bottom: 0;
                }

                a,
                button {
                    max-width: 135px;
                }
            }
        }
    }

    @include media-breakpoint-up(lg) {
        .divider {
            height: 75px !important;
            width: 1px !important;
        }
    }

    @include media-breakpoint-only(lg) {
        .content-card {
            section {
                max-width: 460px;

                .title {
                    font-size: 3.1rem;
                }
            }
        }
    }
}
</style>
<script>
import ContentCard from "Modules/Home/components/ContentCard/index";

export default {
    name: "SavingsFindersLifetimeWarranty",

    components: {
        ContentCard
    },

    methods: {
        show() {
            this.$refs.modal.show();
        }
    }
};
</script>
