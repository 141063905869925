<template>
    <div ref="video" class="video-background">
        <div
            :class="
                'wistia_embed wistia_async_' + videoId + ' wmode=transparent'
            "
        >
            &nbsp;
        </div>
    </div>
</template>

<script>
import _ from "lodash";

export default {
    name: "VideoBackground",

    props: {
        videoId: {
            type: String,
            required: true
        }
    },

    mounted() {
        this.mountVideo();
    },

    methods: {
        isMobile() {
            return window.innerWidth <= 425;
        },

        isTablet() {
            return window.innerWidth > 425 && window.innerWidth <= 990;
        },

        mountVideo() {
            const container = this.$refs.video;
            const height = window.innerHeight;

            if (!this.isMobile()) {
                const scripts = [
                    `//fast.wistia.com/embed/medias/${this.videoId}.jsonp`,
                    "//fast.wistia.net/assets/external/E-v1.js"
                ];

                window._wq = window._wq || [];
                window._wq.push({
                    id: this.videoId,
                    options: {
                        volume: 0,
                        playbar: false,
                        autoPlay: true,
                        playButton: false,
                        wmode: "transparent",
                        volumeControl: false,
                        silentAutoPlay: true,
                        qualityControl: false,
                        smallPlayButton: false,
                        settingsControl: false,
                        controlsVisibleOnLoad: false,
                        videoFoam: {
                            minHeight: this.isTablet() ? height : 560
                        },
                        endVideoBehavior: "loop",
                        stillUrl: require("./images/video_background.jpg")
                    }
                });

                _.forEach(scripts, src => {
                    const script = document.createElement("script");
                    script.src = src;
                    script.async = true;

                    container.appendChild(script);
                });
            }
        }
    }
};
</script>

<style scoped lang="scss">
.video-background {
    position: absolute;
    top: 50%;
    left: 50% !important;
    transform: translate(-50%, -50%);
    object-fit: cover;
    width: 100%;

    @include media-breakpoint-down(md) {
        left: -20%;
        width: auto;
    }
}
</style>
