var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-flex flex-column p-3" },
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            [
              _c("search-by", {
                model: {
                  value: _vm.shopBy,
                  callback: function($$v) {
                    _vm.shopBy = $$v
                  },
                  expression: "shopBy"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.shopBy === "MAKE_MODEL"
        ? _c("make-model", { attrs: { "stock-type": "NEW" } })
        : _vm._e(),
      _vm._v(" "),
      _vm.shopBy === "BODYSTYLE"
        ? _c("body-style", { attrs: { "stock-type": "NEW" } })
        : _vm._e(),
      _vm._v(" "),
      _vm.shopBy === "PRICE"
        ? _c("price", { attrs: { "stock-type": "NEW" } })
        : _vm._e(),
      _vm._v(" "),
      _vm.shopBy === "PAYMENT"
        ? _c("payment", { attrs: { "stock-type": "NEW" } })
        : _vm._e(),
      _vm._v(" "),
      _c("advanced-search", { attrs: { "stock-type": "NEW" } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }