<template>
    <div class="d-flex flex-column p-3">
        <b-row>
            <b-col>
                <search-by v-model="shopBy" />
            </b-col>
        </b-row>

        <make-model v-if="shopBy === 'MAKE_MODEL'" stock-type="NEW" />
        <body-style v-if="shopBy === 'BODYSTYLE'" stock-type="NEW" />
        <price v-if="shopBy === 'PRICE'" stock-type="NEW" />
        <payment v-if="shopBy === 'PAYMENT'" stock-type="NEW" />

        <advanced-search stock-type="NEW" />
    </div>
</template>

<script>
import AdvancedSearch from "./AdvancedSearch";
import SearchBy from "./SearchBy";
import MakeModel from "./MakeModel";
import Price from "./Price";
import BodyStyle from "./BodyStyle";
import Payment from "./Payment";

export default {
    components: {
        AdvancedSearch,
        SearchBy,
        MakeModel,
        Price,
        Payment,
        BodyStyle
    },
    data() {
        return {
            shopBy: "MAKE_MODEL"
        };
    }
};
</script>
