<template>
    <div v-if="show" id="shop-dot-com-block">
        <b-container fluid class="p-sm-0 position-relative">
            <b-row class="content-card">
                <div class="col-12 col-md-8 left-block">
                    <div class="d-flex flex-column text-block">
                        <svg-fallback class="shop-logo" :src="logoImgSvg">
                            <img alt="Shop.com" :src="logoImgPng" />
                        </svg-fallback>

                        <h1 class="title">
                            SHOP.COM has partnered with CarSaver to make buying
                            a new and used car fun, fast and easy.
                        </h1>

                        <hr />
                        <h2 class="subtitle">
                            Enjoy worry-free driving with CarSaver’s Lifetime
                            Warranty Standard.*
                        </h2>
                    </div>
                </div>
                <div
                    class="col-12 col-md-4 d-none d-md-flex flex-column justify-content-center align-items-center right-block"
                >
                    <img
                        class="right-block-img"
                        src="./images/shopper-sm.png"
                        alt="shopper"
                    />
                </div>
            </b-row>
        </b-container>
    </div>
</template>
<script>
import SvgFallback from "Components/SvgFallback";
import logoImgPng from "../Hero/components/ShopHero/images/shop-logo.png";
import logoImgSvg from "../Hero/components/ShopHero/images/shop-logo.svg";
import { get } from "vuex-pathify";
export default {
    name: "ShopDotComBlock",
    components: { SvgFallback },
    data() {
        return {
            logoImgPng,
            logoImgSvg
        };
    },
    computed: {
        campaignType: get("campaign/type"),
        show() {
            const enabledCampaign = "shop";

            return this.campaignType === enabledCampaign;
        }
    }
};
</script>
<style lang="scss">
#shop-dot-com-block {
    padding: 5px 0;
    color: white;
    overflow: hidden;

    .shop-logo {
        margin-left: -10px;
        margin-bottom: 15px;
        margin-top: 12px;

        @include media-breakpoint-up(md) {
            margin-top: 25px;
        }

        img,
        svg {
            width: 100%;
            max-width: 345px;

            @include media-breakpoint-up(md) {
                max-width: 500px;
            }
        }
    }

    .title {
        color: rgba(255, 255, 255, 0.85);
        font-size: px2rem(25);
        line-height: 1.4;
        font-weight: bold;
        letter-spacing: 0;

        @include media-breakpoint-up(md) {
            font-size: px2rem(28);
        }
    }

    .subtitle {
        font-size: px2rem(16);
        line-height: 1.3;
        letter-spacing: 0;

        @include media-breakpoint-up(md) {
            font-size: px2rem(18);
        }
    }

    hr {
        width: 100%;
        max-width: 345px;
        margin-left: 0;
        border: 0;
        border-top: 2px solid rgba(255, 255, 255, 1);
    }
    .container-fluid {
        .content-card {
            background-color: rgba(4, 168, 201, 1);
            width: 100%;
            height: auto;
            min-height: 300px;
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
            border-radius: 2px;
            padding: 20px;
            margin-right: 0;
            margin-left: 0;
        }
    }

    .left-block {
    }

    .right-block {
        height: 200px;

        @include media-breakpoint-up(md) {
            height: inherit;
        }

        &-img {
            max-height: 90%;
            position: absolute;
            bottom: -20px;

            @include media-breakpoint-up(md) {
                max-height: 285px;
                right: calc(100% - 350px);
            }
        }
    }

    .text-block {
    }

    @include media-breakpoint-up(sm) {
        padding: 17.5px 0;
    }

    @include media-breakpoint-down(md) {
        height: 100%;

        .text-block {
        }
    }

    @include media-breakpoint-up(md) {
    }
}
</style>
