<template>
    <div class="d-flex flex-column p-3">
        <b-row>
            <b-col>
                <search-by v-model="shopBy" />
            </b-col>
        </b-row>

        <make-model v-if="shopBy === 'MAKE_MODEL'" stock-type="USED" />
        <body-style v-if="shopBy === 'BODYSTYLE'" stock-type="USED" />
        <price v-if="shopBy === 'PRICE'" stock-type="USED" />
        <payment
            v-if="shopBy === 'PAYMENT'"
            stock-type="USED"
            hide-payment-type
        />

        <advanced-search stock-type="USED" />
    </div>
</template>

<script>
import AdvancedSearch from "./AdvancedSearch";
import SearchBy from "./SearchBy";
import MakeModel from "./MakeModel";
import Price from "./Price";
import Payment from "./Payment";
import BodyStyle from "./BodyStyle";

export default {
    components: {
        AdvancedSearch,
        SearchBy,
        MakeModel,
        Price,
        Payment,
        BodyStyle
    },
    data() {
        return {
            shopBy: "MAKE_MODEL"
        };
    }
};
</script>
