var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.show
    ? _c(
        "div",
        { attrs: { id: "shop-dot-com-block" } },
        [
          _c(
            "b-container",
            { staticClass: "p-sm-0 position-relative", attrs: { fluid: "" } },
            [
              _c("b-row", { staticClass: "content-card" }, [
                _c("div", { staticClass: "col-12 col-md-8 left-block" }, [
                  _c(
                    "div",
                    { staticClass: "d-flex flex-column text-block" },
                    [
                      _c(
                        "svg-fallback",
                        {
                          staticClass: "shop-logo",
                          attrs: { src: _vm.logoImgSvg }
                        },
                        [
                          _c("img", {
                            attrs: { alt: "Shop.com", src: _vm.logoImgPng }
                          })
                        ]
                      ),
                      _vm._v(" "),
                      _c("h1", { staticClass: "title" }, [
                        _vm._v(
                          "\n                        SHOP.COM has partnered with CarSaver to make buying\n                        a new and used car fun, fast and easy.\n                    "
                        )
                      ]),
                      _vm._v(" "),
                      _c("hr"),
                      _vm._v(" "),
                      _c("h2", { staticClass: "subtitle" }, [
                        _vm._v(
                          "\n                        Enjoy worry-free driving with CarSaver’s Lifetime\n                        Warranty Standard.*\n                    "
                        )
                      ])
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "col-12 col-md-4 d-none d-md-flex flex-column justify-content-center align-items-center right-block"
                  },
                  [
                    _c("img", {
                      staticClass: "right-block-img",
                      attrs: {
                        src: require("./images/shopper-sm.png"),
                        alt: "shopper"
                      }
                    })
                  ]
                )
              ])
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }