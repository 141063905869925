var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-row",
    { staticClass: "mt-1" },
    [
      _c(
        "b-col",
        [
          _c(
            "b-link",
            {
              staticClass: "advanced-search",
              on: { click: _vm.goToAdvancedSearch }
            },
            [_vm._v("\n            Advanced Search\n        ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }