<template>
    <b-row class="mt-1">
        <b-col>
            <b-link class="advanced-search" @click="goToAdvancedSearch">
                Advanced Search
            </b-link>
        </b-col>
    </b-row>
</template>
<script>
export default {
    props: {
        stockType: {
            type: String,
            required: true
        }
    },
    methods: {
        goToAdvancedSearch() {
            localStorage.setItem("advanced-search", this.stockType);
            document.location = "/advanced-search";
        }
    }
};
</script>

<style lang="scss" scoped>
a.advanced-search {
    font-family: $helvetica-56-italic;
    font-style: italic;
    font-size: px2rem(14);
    color: $primary-orange;
}
</style>
