<template>
    <div class="mb-2">
        <div
            :class="{ active: active }"
            class="body-style-card px-2 d-flex flex-column justify-content-around h-100 mx-auto"
            @click="click"
        >
            <div>
                <b-img :src="image" fluid />
            </div>
            <div>
                <slot />
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.body-style-card {
    font-size: px2rem(12);
    color: $dark-gray;
    min-height: 80px;
    text-align: center;
    cursor: pointer;

    &.active {
        border: solid 1px $primary-blue;
        border-radius: 8px;
        color: $primary-blue;
        font-weight: bold;
    }

    @include media-breakpoint-up(sm) {
        min-height: 120px;
        max-width: 180px;
    }
}
</style>

<script>
export default {
    props: {
        image: {
            type: String,
            required: true
        },
        active: {
            type: Boolean,
            required: false,
            default: false
        }
    },

    methods: {
        click() {
            this.$emit("click");
        }
    }
};
</script>
