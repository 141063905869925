<template>
    <div class="deal-card d-flex flex-column d-md-block">
        <a
            :href="urls.listings"
            class="image-wrapper d-flex justify-content-center"
        >
            <b-img-lazy v-webp :src="urls.img" :alt="deal.bodyStyle" />
        </a>
        <div
            class="header p-3 pt-md-4 pl-md-4 pr-md-4 d-flex justify-content-between d-md-block"
        >
            <h3>{{ $t("component.topDeals.bodyTypes." + deal.bodyStyle) }}</h3>
            <p>
                <span class="count">
                    {{ deal.inventoryCount | numeral("0,0") }}
                </span>
                {{ $t("component.topDeals.card.subtitles") }}
            </p>
        </div>

        <div class="deal-pricing-block-wrapper">
            <div class="deal-pricing-block d-flex flex-column pr-4 pl-4 pb-4">
                <div class="pricing-tabs-wrapper mb-4" @click="clickHandler">
                    <div class="pricing-tabs d-flex justify-content-between">
                        <div v-if="!averageSavingsBelowZero"
                            :class="{ active: savingsActive }"
                            :aria-pressed="savingsActive"
                            name="savings-tab"
                            class="tab"
                        >
                            {{ $t("component.topDeals.savings") }}

                            <span class="border-block d-flex">
                                <span class="part" />
                                <span class="caret" />
                                <span class="part" />
                            </span>
                        </div>
                        <div
                            :class="{ active: priceActive }"
                            :aria-pressed="priceActive"
                            name="price-tab"
                            class="tab"
                        >
                            {{ $t("component.topDeals.price") }}

                            <span class="border-block d-flex">
                                <span class="part" />
                                <span class="caret" />
                                <span class="part" />
                            </span>
                        </div>
                        <div
                            :class="{ active: paymentActive }"
                            :aria-pressed="paymentActive"
                            name="payment-tab"
                            class="tab"
                        >
                            {{ $t("component.topDeals.payment") }}

                            <span class="border-block d-flex">
                                <span class="part" />
                                <span class="caret" />
                                <span class="part" />
                            </span>
                        </div>
                    </div>

                    <div v-if="savingsActive" class="savings-wrapper">
                        <p class="title">
                            {{ $t("component.topDeals.averageSavings") }}
                            <tooltip-disclaimer
                                v-if="deal.averageSavings"
                                :title="deal.averageSavings.disclaimer"
                                is-deal-card
                            />
                        </p>
                        <p class="center-context">
                            <sup>$</sup
                            >{{ deal.averageSavings.value | currencyFormatter }}
                        </p>
                        <p class="sub-title">
                            {{ $t("component.topDeals.maxSavings") }} ${{
                                deal.biggestSavings.value | currencyFormatter
                            }}
                            <tooltip-disclaimer
                                v-if="deal.biggestSavings"
                                :title="deal.biggestSavings.disclaimer"
                                is-deal-card
                            />
                        </p>
                    </div>

                    <div v-if="priceActive" class="price-wrapper">
                        <p class="title">
                            {{ $t("component.topDeals.asLowAs") }}
                            <tooltip-disclaimer
                                v-if="deal.lowestPrice"
                                :title="deal.lowestPrice.disclaimer"
                                is-deal-card
                            />
                        </p>
                        <p class="center-context">
                            <sup>$</sup
                            >{{ deal.lowestPrice.value | currencyFormatter }}
                        </p>
                        <p class="sub-title">
                            {{ $t("component.topDeals.plusFees") }}
                        </p>
                    </div>

                    <div v-if="paymentActive" class="payment-wrapper">
                        <p class="title">
                            {{ $t("component.topDeals.starting") }}
                            <tooltip-disclaimer
                                v-if="deal.lowestPayment"
                                :title="deal.lowestPayment.disclaimer"
                                is-deal-card
                            />
                        </p>
                        <p class="center-context">
                            <sup>$</sup
                            >{{ deal.lowestPayment.value | currencyFormatter
                            }}<sub
                                >/{{ $t("component.topDeals.abbr.month") }}</sub
                            >
                        </p>
                        <p v-if="isFinance" class="sub-title">
                            {{ $t("component.topDeals.finance") }}
                        </p>
                        <p v-else-if="isLease" class="sub-title">
                            {{ $t("component.topDeals.lease") }}
                        </p>
                    </div>
                </div>

                <div
                    class="view-deals-btn-wrapper d-flex justify-content-center d-md-block"
                >
                    <a
                        :href="viewDetailsUrl"
                        class="view-deals-btn d-none d-md-block btn btn-primary btn-lg"
                    >
                        {{ $t("component.topDeals.viewDeals") | capitalize }}
                    </a>

                    <a
                        :href="viewDetailsUrl"
                        class="mobile-view-deals-btn view-deals-btn d-block d-md-none btn btn-outline-primary btn-lg"
                    >
                        {{ $t("component.topDeals.viewDeals") | capitalize }}
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import numeral from "numeral";
import helper from "../../helper";
import _ from "lodash";
import TooltipDisclaimer from "Components/TooltipDisclaimer/index";

export default {
    name: "DealCard",
    components: { TooltipDisclaimer },
    filters: {
        currencyFormatter: function(value) {
            const emptyValue = "- -";

            if (value === 0 || _.isNil(value) || value === "") {
                return emptyValue;
            }

            if (typeof value !== "number") {
                return value;
            }

            return numeral(value).format("0,0[.]00");
        },
        capitalize: function(value) {
            if (typeof value !== "string") {
                return value;
            }

            const words = value.split(" ");
            const capitalized = words
                .map(function(word) {
                    return _.capitalize(word);
                })
                .join(" ");

            return capitalized;
        }
    },
    props: {
        deal: {
            type: Object,
            default: () => ({
                bodyStyle: "",
                biggestSavings: {},
                lowestPrice: {},
                lowestPayment: {},
                averageSavings: {},
                inventoryCount: ""
            })
        }
    },
    mounted() {
        if(this.averageSavingsBelowZero) {
            this.priceActive = true
        }
        else this.savingsActive = true
    },
    data() {
        return {
            savingsActive: false,
            priceActive: false,
            paymentActive: false,
            urls: helper.getUrls(this.deal.bodyStyle)
        };
    },
    computed: {
        viewDetailsUrl() {
            let url = this.urls.listings;

            if (this.paymentActive) {
                url += "&sort=quote.monthlyPayment,asc";
            } else if (this.savingsActive) {
                url += "&sort=savings,desc";
            } else {
                url += "&sort=price,asc";
            }

            return url;
        },
        isFinance() {
            return this.deal.lowestPayment.type === "Finance";
        },
        isLease() {
            return this.deal.lowestPayment.type === "Lease";
        },
        averageSavingsBelowZero() {
            return this.deal.averageSavings.value <= 0;
        }
    },
    methods: {
        clickHandler: function(e) {
            let tab = "";

            if (e.target && e.target.getAttribute("name")) {
                tab = e.target.getAttribute("name").toLowerCase();

                switch (tab) {
                    case "savings-tab":
                        this.savingsActive = true;
                        this.priceActive = false;
                        this.paymentActive = false;
                        break;
                    case "price-tab":
                        this.savingsActive = false;
                        this.priceActive = true;
                        this.paymentActive = false;
                        break;
                    case "payment-tab":
                        this.savingsActive = false;
                        this.priceActive = false;
                        this.paymentActive = true;
                        break;
                    default:
                        this.savingsActive = true;
                        this.priceActive = false;
                        this.paymentActive = false;
                }
            } else {
                // tab not selected
            }
        }
    }
};
</script>
<style lang="scss" scoped>
$card-height: 385px;
$card-max-width: 1140px;

.deal-card {
    overflow-x: hidden;
    position: relative;
    color: white;
    width: 100%;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.2);

    a > img {
        width: $card-max-width;
        height: $card-height;
    }

    .header {
        position: absolute;
        top: 0px;
        z-index: 1;
        margin-bottom: 30px;

        .count {
            font-family: $helvetica-55-Roman;
        }

        h3 {
            text-transform: capitalize;
            font-size: px2rem(40);
        }
        p {
            text-transform: capitalize;
            font-size: px2rem(14);
        }
    }

    .deal-pricing-block-wrapper {
        position: absolute;
        top: 0;

        .deal-pricing-block {
            padding-top: 110px;
            height: $card-height;
            width: 245px;
            border-radius: 0;
            background-color: rgba(0, 76, 145, 0.75);
            box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.5);
            position: relative;

            a.view-deals-btn {
                position: absolute;
                bottom: px2rem(18);
                opacity: 0.75;
                box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);
                font-family: $helvetica-55-Roman;
            }

            .pricing-tabs-wrapper {
                .pricing-tabs {
                    text-transform: capitalize;
                    border-top: 1px solid white;
                    font-family: $helvetica-46-light-italic;
                    margin-bottom: 25px;
                    height: auto;

                    .tab {
                        flex: 1;
                        text-align: center;
                        line-height: 1em;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        padding-top: 10px;

                        span {
                        }

                        &:hover {
                            cursor: pointer;
                        }
                    }

                    .active {
                        color: $white;
                        font-style: normal;
                        font-family: $helvetica-65-medium;

                        .caret {
                            display: inline;
                        }
                    }
                }

                .savings-wrapper,
                .price-wrapper,
                .payment-wrapper {
                    text-align: center;

                    sup {
                        top: -0.7em;
                        font-size: 45%;
                    }

                    sub {
                        font-weight: normal;
                        font-family: $helvetica-46-light-italic;
                        font-size: px2rem(18);
                        bottom: 0;
                    }

                    p {
                        margin: 0;

                        a {
                            color: inherit;
                        }
                    }

                    .title,
                    .sub-title {
                        font-family: $helvetica-46-light-italic;

                        i {
                            color: #fff;
                        }
                    }

                    .center-context {
                        font-size: px2rem(60);
                        line-height: px2rem(70);
                    }
                }
            }

            .view-deals-btn {
                width: 205px;
                height: 45px;
                font-size: px2rem(14);
                line-height: px2rem(27.5);
            }
        }
    }

    .border-block {
        bottom: 0;
        left: 0;
        height: 1px;
        width: 100%;
        padding-top: 10px;

        .caret {
            height: 10px;
            width: 10px;
            position: relative;
            top: -5px;
            margin: 1px;
            border: none;
            border-left: 1px solid;
            border-bottom: 1px solid;
            transform: rotate(-45deg);
            display: none;
        }
        .part {
            height: 0;
            border-top: 1px solid;
            -ms-flex: 1 0 auto;
            flex: 1 0 auto;
        }
    }

    @include media-breakpoint-down(sm) {
        position: relative;
        display: flex;
        border-left: none;
        border-right: none;

        a,
        .header,
        .deal-pricing-block-wrapper {
            position: static;
        }

        a > img {
            width: auto;
            height: 260px;
        }

        .image-wrapper {
            order: 2;
        }

        .header {
            order: 1;
            color: #333;
            margin: 0;
            background-color: white;

            h3,
            p {
                margin: auto 0;
            }

            h3 {
                font-size: px2rem(30);
                margin-right: 15px;
            }

            p {
                text-align: right;
                line-height: 1em;

                span {
                    display: block;
                }
            }
        }

        .deal-pricing-block-wrapper {
            order: 3;
            color: #757575;

            .deal-pricing-block {
                width: 100%;
                height: auto;
                background-color: white;
                border-radius: unset;
                border: none;
                padding-top: 20px;

                a {
                    opacity: 1;
                }

                .view-deals-btn-wrapper {
                    width: 100%;

                    .view-deals-btn,
                    .mobile-view-deals-btn {
                        position: static;
                    }
                }

                .pricing-tabs-wrapper {
                    .center-context {
                        color: $primary-blue;
                    }

                    .pricing-tabs {
                        border-top: 1px solid #d4d4d4;

                        .border-block {
                            color: #d4d4d4;
                        }

                        .active {
                            color: $primary-blue;

                            .caret {
                                color: #d4d4d4;
                            }
                        }
                    }
                }

                .view-deals-btn {
                    width: 275px;
                }
            }
        }
    }
}
</style>
