<template>
    <div class="d-flex p-sm-0" v-if="showSell">
    <b-container fluid class="mt-3">
        <b-row>
            <b-col cols="12" md="7" class="px-lg-0 pr-md-0 d-none d-md-block">
                <div class="w-100 h-100 syc-banner">
                    <div class="w-75 text-center text-wrapper">
                        <h1>{{ title }}</h1>
                    </div>
                    <div class="d-flex justify-content-center align-items-center my-5">
                        <get-started-btn />
                    </div>
                    <div class="px-3">
                        <sell-your-car-disclaimer class="syc-disclaimer text-white" />
                    </div>

                </div>
            </b-col>
            <b-col cols="12" md="5" class="px-lg-0 pl-md-0">
                <b-img fluid :src="sycImage" class="d-none d-md-block image"></b-img>
                <b-img fluid :src="sycImageSm" class="d-md-none image"></b-img>
            </b-col>
            <b-col cols="12" md="7" class="px-lg-0 pr-md-0 d-md-none">
                <div class="w-100 h-100 card">
                    <div class="p-20 text-center text-wrapper">
                        <h1>{{ title }}</h1>
                    </div>
                    <div class="d-flex justify-content-center align-items-center">
                        <get-started-btn />
                    </div>
                    <sell-your-car-disclaimer class="syc-disclaimer" />
                </div>
            </b-col>
        </b-row>
    </b-container>
    </div>
</template>

<script>
import sycBannerImage from "./images/sycBannerImage.jpg"
import sycBannerImageSm from "./images/sycBannerImage-sm.jpg"
import {get} from "vuex-pathify";
import SellYourCarDisclaimer from "Components/SellYourCarDisclaimer";
import getStartedBtn from "Components/GetStartedBtn"

export default {
    name: "sell-your-car-banner",
    components: {SellYourCarDisclaimer, getStartedBtn},
    data() {
        return {
            sycImage: sycBannerImage,
            sycImageSm: sycBannerImageSm,
            title: `Get a real offer for your car in 2 minutes or less!`,
        };
    },
    computed: {
        isStateSycWhitelisted: get("location/isStateSycWhitelisted"),
        sellYourCarEnabled: get("location/isSellYourCarEnabled"),
        showSell() {
            return this.sellYourCarEnabled && this.isStateSycWhitelisted;
        },
    }
}

</script>

<style lang="scss" scoped>
.syc-disclaimer {

}

.syc-banner {
    background-color: rgba($primary-blue, 1);
    color: $white;
}
.text-wrapper {
    margin: 0 auto;
    padding-top: 60px;

    @include media-breakpoint-down(sm) {
        padding-top: 20px;
    }
}
h1 {
    font-weight: lighter;
    @include media-breakpoint-down(sm) {
        font-size: px2rem(30);
    }
}
.button {
    max-width: 280px;
    width: 75%;
    font-weight: bold;
    @include media-breakpoint-down(sm) {
        max-width: 280px;
        width: 100%;
    }
}
.card {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}
.p-20 {
    padding: 20px;
}
.image {
    width: 100%;
    height: 100%;
    display: block;
}

</style>
