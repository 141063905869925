var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "savingsFinderLifetimeWarranty" } },
    [
      _c("content-card", [
        _c(
          "section",
          {
            staticClass:
              "col-12 d-flex flex-column flex-md-row justify-content-md-between align-items-md-center w-100 px-sm-3 px-md-0"
          },
          [
            _c("div", { staticClass: "d-flex flex-column" }, [
              _c("h1", { staticClass: "title" }, [
                _vm._v(
                  "\n                    " +
                    _vm._s(
                      _vm.$t(
                        "component.savingsFindersLifetimeWarranty.savingsFinder"
                      )
                    ) +
                    "\n                "
                )
              ]),
              _vm._v(" "),
              _c(
                "p",
                {
                  staticClass: "sub-text",
                  staticStyle: { "max-width": "290px" }
                },
                [
                  _vm._v(
                    "\n                    " +
                      _vm._s(
                        _vm.$t(
                          "component.savingsFindersLifetimeWarranty.seeHow"
                        )
                      ) +
                      "\n                "
                  )
                ]
              )
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "d-flex justify-content-center align-items-center"
              },
              [
                _c(
                  "b-link",
                  {
                    staticClass: "btn btn-outline-primary btn-xlg",
                    attrs: { href: "/pick-your-flow" }
                  },
                  [
                    _vm._v(
                      "\n                    " +
                        _vm._s(
                          _vm.$t("component.savingsFindersLifetimeWarranty.go")
                        ) +
                        "\n                "
                    )
                  ]
                )
              ],
              1
            )
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "d-none d-md-flex divider" }),
        _vm._v(" "),
        _c(
          "section",
          {
            staticClass:
              "col-12 d-none d-md-flex flex-row justify-content-between align-items-center p-0"
          },
          [
            _c("div", { staticClass: "d-flex flex-column" }, [
              _c("h1", { staticClass: "title" }, [
                _vm._v(
                  "\n                    " +
                    _vm._s(
                      _vm.$t(
                        "component.savingsFindersLifetimeWarranty.lifeTimeWarranty"
                      )
                    ) +
                    "\n                "
                )
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "sub-text" }, [
                _vm._v(
                  "\n                    " +
                    _vm._s(
                      _vm.$t("component.savingsFindersLifetimeWarranty.title")
                    ) +
                    "\n                "
                )
              ])
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "d-flex justify-content-center align-items-center"
              },
              [
                _c(
                  "a",
                  {
                    staticClass: "btn btn-outline-primary btn-xlg poop",
                    attrs: { href: "/warranty" }
                  },
                  [
                    _vm._v(
                      "\n                    " +
                        _vm._s(
                          _vm.$t(
                            "component.savingsFindersLifetimeWarranty.learnMore"
                          )
                        ) +
                        "\n                "
                    )
                  ]
                )
              ]
            )
          ]
        )
      ]),
      _vm._v(" "),
      _c("content-card", { staticClass: "d-flex d-md-none" }, [
        _c(
          "section",
          { staticClass: "col-12 d-flex flex-column w-100 px-sm-3" },
          [
            _c("div", { staticClass: "d-flex flex-column" }, [
              _c("h1", { staticClass: "title" }, [
                _vm._v(
                  "\n                    Lifetime Warranty\n                "
                )
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "sub-text" }, [
                _vm._v(
                  "\n                    " +
                    _vm._s(
                      _vm.$t("component.savingsFindersLifetimeWarranty.title")
                    ) +
                    "\n                "
                )
              ])
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "d-flex justify-content-center align-items-center"
              },
              [
                _c(
                  "a",
                  {
                    staticClass: "btn btn-outline-primary btn-xlg",
                    attrs: { href: "/warranty" }
                  },
                  [
                    _vm._v(
                      "\n                    " +
                        _vm._s(
                          _vm.$t(
                            "component.savingsFindersLifetimeWarranty.learnMore"
                          )
                        ) +
                        "\n                "
                    )
                  ]
                )
              ]
            )
          ]
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }